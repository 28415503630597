// @generated by protoc-gen-connect-query v1.4.1 with parameter "target=ts"
// @generated from file room/v1/room_api.proto (package room.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { AddRoomUserRequest, AddRoomUserResponse, CreateRoomRequest, CreateRoomResponse, DeleteRoomRequest, DeleteRoomResponse, DeleteRoomUserRequest, DeleteRoomUserResponse, GetAffiliateRoomRequest, GetAffiliateRoomResponse, GetRoomRequest, GetRoomResponse, ListAffiliateRoomsRequest, ListAffiliateRoomsResponse, ListAllRoomPlayersRequest, ListAllRoomPlayersResponse, ListInvitedRoomsRequest, ListInvitedRoomsResponse, ListRoomsRequest, ListRoomsResponse, UpdateRoomEntityCustomFeeRequest, UpdateRoomEntityCustomFeeResponse, UpdateRoomFeeSettingRequest, UpdateRoomFeeSettingResponse, UpdateUserRoomPermissionRequest, UpdateUserRoomPermissionResponse } from "./room_api_pb.js";

/**
 * @generated from rpc room.v1.RoomAPI.CreateRoom
 */
export const createRoom = {
  localName: "createRoom",
  name: "CreateRoom",
  kind: MethodKind.Unary,
  I: CreateRoomRequest,
  O: CreateRoomResponse,
  service: {
    typeName: "room.v1.RoomAPI"
  }
} as const;

/**
 * @generated from rpc room.v1.RoomAPI.ListRooms
 */
export const listRooms = {
  localName: "listRooms",
  name: "ListRooms",
  kind: MethodKind.Unary,
  I: ListRoomsRequest,
  O: ListRoomsResponse,
  service: {
    typeName: "room.v1.RoomAPI"
  }
} as const;

/**
 * @generated from rpc room.v1.RoomAPI.ListAffiliateRooms
 */
export const listAffiliateRooms = {
  localName: "listAffiliateRooms",
  name: "ListAffiliateRooms",
  kind: MethodKind.Unary,
  I: ListAffiliateRoomsRequest,
  O: ListAffiliateRoomsResponse,
  service: {
    typeName: "room.v1.RoomAPI"
  }
} as const;

/**
 * @generated from rpc room.v1.RoomAPI.ListInvitedRooms
 */
export const listInvitedRooms = {
  localName: "listInvitedRooms",
  name: "ListInvitedRooms",
  kind: MethodKind.Unary,
  I: ListInvitedRoomsRequest,
  O: ListInvitedRoomsResponse,
  service: {
    typeName: "room.v1.RoomAPI"
  }
} as const;

/**
 * @generated from rpc room.v1.RoomAPI.GetRoom
 */
export const getRoom = {
  localName: "getRoom",
  name: "GetRoom",
  kind: MethodKind.Unary,
  I: GetRoomRequest,
  O: GetRoomResponse,
  service: {
    typeName: "room.v1.RoomAPI"
  }
} as const;

/**
 * @generated from rpc room.v1.RoomAPI.GetAffiliateRoom
 */
export const getAffiliateRoom = {
  localName: "getAffiliateRoom",
  name: "GetAffiliateRoom",
  kind: MethodKind.Unary,
  I: GetAffiliateRoomRequest,
  O: GetAffiliateRoomResponse,
  service: {
    typeName: "room.v1.RoomAPI"
  }
} as const;

/**
 * @generated from rpc room.v1.RoomAPI.AddRoomUser
 */
export const addRoomUser = {
  localName: "addRoomUser",
  name: "AddRoomUser",
  kind: MethodKind.Unary,
  I: AddRoomUserRequest,
  O: AddRoomUserResponse,
  service: {
    typeName: "room.v1.RoomAPI"
  }
} as const;

/**
 * @generated from rpc room.v1.RoomAPI.DeleteRoomUser
 */
export const deleteRoomUser = {
  localName: "deleteRoomUser",
  name: "DeleteRoomUser",
  kind: MethodKind.Unary,
  I: DeleteRoomUserRequest,
  O: DeleteRoomUserResponse,
  service: {
    typeName: "room.v1.RoomAPI"
  }
} as const;

/**
 * @generated from rpc room.v1.RoomAPI.DeleteRoom
 */
export const deleteRoom = {
  localName: "deleteRoom",
  name: "DeleteRoom",
  kind: MethodKind.Unary,
  I: DeleteRoomRequest,
  O: DeleteRoomResponse,
  service: {
    typeName: "room.v1.RoomAPI"
  }
} as const;

/**
 * @generated from rpc room.v1.RoomAPI.UpdateUserRoomPermission
 */
export const updateUserRoomPermission = {
  localName: "updateUserRoomPermission",
  name: "UpdateUserRoomPermission",
  kind: MethodKind.Unary,
  I: UpdateUserRoomPermissionRequest,
  O: UpdateUserRoomPermissionResponse,
  service: {
    typeName: "room.v1.RoomAPI"
  }
} as const;

/**
 * @generated from rpc room.v1.RoomAPI.ListAllRoomPlayers
 */
export const listAllRoomPlayers = {
  localName: "listAllRoomPlayers",
  name: "ListAllRoomPlayers",
  kind: MethodKind.Unary,
  I: ListAllRoomPlayersRequest,
  O: ListAllRoomPlayersResponse,
  service: {
    typeName: "room.v1.RoomAPI"
  }
} as const;

/**
 * @generated from rpc room.v1.RoomAPI.UpdateRoomFeeSetting
 */
export const updateRoomFeeSetting = {
  localName: "updateRoomFeeSetting",
  name: "UpdateRoomFeeSetting",
  kind: MethodKind.Unary,
  I: UpdateRoomFeeSettingRequest,
  O: UpdateRoomFeeSettingResponse,
  service: {
    typeName: "room.v1.RoomAPI"
  }
} as const;

/**
 * @generated from rpc room.v1.RoomAPI.UpdateRoomEntityCustomFee
 */
export const updateRoomEntityCustomFee = {
  localName: "updateRoomEntityCustomFee",
  name: "UpdateRoomEntityCustomFee",
  kind: MethodKind.Unary,
  I: UpdateRoomEntityCustomFeeRequest,
  O: UpdateRoomEntityCustomFeeResponse,
  service: {
    typeName: "room.v1.RoomAPI"
  }
} as const;
