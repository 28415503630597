import { chakra, Container } from "@chakra-ui/react";
import { Loading } from "components";
import { StateProvider } from "hooks/provider";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { MainLayout } from "../layout";
import ViewFees from "fees/ViewFees";
import MyFees from "fees/MyFees";
import ManageFees from "fees/ManageFees";
import FeesAnalysis from "fees/FeesAnalysis";
import AggregatedFeesAnalysis from "fees/AggregatedFeesAnalysis";

const BoardsPage = React.lazy(() => import("board/pages/BoardsPage"));
const BoardPage = React.lazy(() => import("board/pages/BoardPage"));
const NewBoardPage = React.lazy(() => import("board/pages/NewBoardPage"));
const MainDashboardPage = React.lazy(
  () => import("dashboard/pages/MainDashboardPage")
);
const RoomsPage = React.lazy(() => import("room/pages/RoomsPage"));
const RoomPage = React.lazy(() => import("room/pages/RoomPage"));
const ProfilePage = React.lazy(() => import("profile/pages/ProfilePage"));
const NotificationPage = React.lazy(
  () => import("notification/pages/NotificationsPage")
);
const NewDiaryPage = React.lazy(() => import("diary/pages/NewDiaryPage"));
const MyDiaryPage = React.lazy(() => import("diary/pages/MyDiaryPage"));
const DiaryPage = React.lazy(() => import("diary/pages/DiaryPage"));
const MyDiarySettingPage = React.lazy(
  () => import("diary/pages/MyDiarySettingPage")
);
const MyStudentsPage = React.lazy(() => import("diary/pages/MyStudentsPage"));
const MyRoomStudentsPage = React.lazy(
  () => import("diary/pages/MyRoomStudentsPage")
);
const StudentDiaryPage = React.lazy(
  () => import("diary/pages/StudentDiaryPage")
);
const ToUserChatPage = React.lazy(() => import("chat/pages/ToUserChatPage"));
const UserChatPage = React.lazy(() => import("chat/pages/UserChatPage"));
const FromUserChatPage = React.lazy(
  () => import("chat/pages/FromUserChatPage")
);
const ByMeChatPage = React.lazy(() => import("chat/pages/ByMeChatPage"));
const ByMeRoomChatPage = React.lazy(
  () => import("chat/pages/ByMeRoomChatPage")
);
const MyAffiliatesPage = React.lazy(
  () => import("affiliate/pages/MyAffiliatesPage")
);
const AcademyNoticeBoard = React.lazy(() => import("noticeboard/NoticeBoard"));
const RoomNoticeBoard = React.lazy(() => import("noticeboard/RoomNoticeBoard"));
const MarkAttendance = React.lazy(() => import("attendance/MarkAttendance"));
const ManageAttendance = React.lazy(
  () => import("attendance/ManageAttendance")
);
const ViewAttendance = React.lazy(() => import("attendance/ViewAttendance"));
const AttendanceAnalysis = React.lazy(
  () => import("attendance/AttendanceAnalysis")
);
const MyAttendance = React.lazy(() => import("attendance/MyAttendance"));
const Tournaments = React.lazy(
  () => import("tournament/pages/TournamentsPage")
);

const LoadingFallback = () => {
  return (
    <Container>
      <Loading text="" />
    </Container>
  );
};

export default function Home() {
  return (
    <StateProvider>
      <MainLayout>
        <chakra.div>
          <Routes>
            <Route
              index
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <MainDashboardPage />
                </React.Suspense>
              }
            />

            <Route
              path="/boards"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <BoardsPage />
                </React.Suspense>
              }
            />
            <Route
              path="/board/:id"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <BoardPage />
                </React.Suspense>
              }
            />
            <Route
              path="/new-board"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <NewBoardPage />
                </React.Suspense>
              }
            />
            <Route
              path="/rooms"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <RoomsPage fetchInvited={false} />
                </React.Suspense>
              }
            />
            <Route
              path="/invited-rooms"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <RoomsPage fetchInvited={true} />
                </React.Suspense>
              }
            />
            <Route
              path="/room/:id"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <RoomPage />
                </React.Suspense>
              }
            />
            <Route
              path="/profile"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <ProfilePage />
                </React.Suspense>
              }
            />
            <Route
              path="/notifications"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <NotificationPage />
                </React.Suspense>
              }
            />
            <Route
              path="/new-diary"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <NewDiaryPage />
                </React.Suspense>
              }
            />
            <Route
              path="/my-diary"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <MyDiaryPage />
                </React.Suspense>
              }
            />
            <Route
              path="/my-diary/setting"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <MyDiarySettingPage />
                </React.Suspense>
              }
            />
            <Route
              path="/diary/:id"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <DiaryPage />
                </React.Suspense>
              }
            />
            <Route
              path="/my-student-diaries"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <MyStudentsPage />
                </React.Suspense>
              }
            />
            <Route
              path="/my-student-diaries/:id"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <MyRoomStudentsPage />
                </React.Suspense>
              }
            />
            <Route
              path="/student-diary/:id"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <StudentDiaryPage />
                </React.Suspense>
              }
            />
            <Route
              path="/my-chat"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <UserChatPage />
                </React.Suspense>
              }
            />
            <Route
              path="/my-chat/:id"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <FromUserChatPage />
                </React.Suspense>
              }
            />
            <Route
              path="/by-me-chat"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <ByMeChatPage />
                </React.Suspense>
              }
            />
            <Route
              path="/by-me-chat/:id"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <ByMeRoomChatPage />
                </React.Suspense>
              }
            />
            <Route
              path="/chats-to/:id"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <ToUserChatPage />
                </React.Suspense>
              }
            />
            <Route
              path="/my-affiliates"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <MyAffiliatesPage />
                </React.Suspense>
              }
            />
            <Route
              path="/my-academy-notice-board"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <AcademyNoticeBoard />
                </React.Suspense>
              }
            />
            <Route
              path="/my-academy-notice-board/:id"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <RoomNoticeBoard />
                </React.Suspense>
              }
            />
            <Route
              path="/mark-attendance/:id"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <MarkAttendance />
                </React.Suspense>
              }
            />
            <Route
              path="/manage-attendance/:id"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <ManageAttendance />
                </React.Suspense>
              }
            />
            <Route
              path="/view-attendance"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <ViewAttendance />
                </React.Suspense>
              }
            />
            <Route
              path="/attendance-analysis/:id"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <AttendanceAnalysis />
                </React.Suspense>
              }
            />

            <Route
              path="/my-attendance"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <MyAttendance />
                </React.Suspense>
              }
            />

            <Route
              path="/tournaments"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <Tournaments />
                </React.Suspense>
              }
            />

            <Route
              path="/view-fees"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <ViewFees />
                </React.Suspense>
              } />

            <Route
              path="/my-fees"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <MyFees />
                </React.Suspense>
              } />

            <Route
              path="/manage-fees/:id"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <ManageFees />
                </React.Suspense>
              } />

            <Route
              path="/fees-analysis/:id"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <FeesAnalysis />
                </React.Suspense>
              } />

            <Route
              path="/fees-analysis"
              element={
                <React.Suspense fallback={<LoadingFallback />}>
                  <AggregatedFeesAnalysis />
                </React.Suspense>
              } />

          </Routes>
        </chakra.div>
      </MainLayout>
    </StateProvider>
  );
}
