// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file aggregate/v1/aggregate_api.proto (package aggregate.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { User } from "../../base/v1/user_pb.js";
import { Affiliate } from "../../affiliate/v1/affiliate_pb.js";
import { PlayerProfile } from "../../user/v1/user_api_pb.js";
import { Room } from "../../room/v1/room_pb.js";
import { Holiday, Session } from "../../attendance/v1/attendance_pb.js";
import { Tournament } from "../../tournament/v1/tournament_pb.js";
import { Notice } from "../../notice/v1/notice_pb.js";
import { Fee } from "../../fee/v1/fee_pb.js";

/**
 * @generated from message aggregate.v1.GetUserAggregateProfileRequest
 */
export const GetUserAggregateProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "aggregate.v1.GetUserAggregateProfileRequest",
  () => [
    { no: 1, name: "notification_created_on_after", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message aggregate.v1.GetUserAggregateProfileResponse
 */
export const GetUserAggregateProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "aggregate.v1.GetUserAggregateProfileResponse",
  () => [
    { no: 1, name: "user", kind: "message", T: User },
    { no: 2, name: "max_boards_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "created_boards_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "my_affiliates", kind: "message", T: Affiliate, repeated: true },
    { no: 5, name: "player_profile", kind: "message", T: PlayerProfile },
  ],
);

/**
 * @generated from message aggregate.v1.GetBoardAggregateRoomsRequest
 */
export const GetBoardAggregateRoomsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "aggregate.v1.GetBoardAggregateRoomsRequest",
  () => [
    { no: 1, name: "board_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message aggregate.v1.GetBoardAggregateRoomsResponse
 */
export const GetBoardAggregateRoomsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "aggregate.v1.GetBoardAggregateRoomsResponse",
  () => [
    { no: 1, name: "rooms", kind: "message", T: Room, repeated: true },
    { no: 2, name: "room_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message aggregate.v1.GetRoomSessionsAggregateRequest
 */
export const GetRoomSessionsAggregateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "aggregate.v1.GetRoomSessionsAggregateRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message aggregate.v1.GetRoomSessionsAggregateResponse
 */
export const GetRoomSessionsAggregateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "aggregate.v1.GetRoomSessionsAggregateResponse",
  () => [
    { no: 1, name: "room", kind: "message", T: Room },
    { no: 2, name: "sessions", kind: "message", T: Session, repeated: true },
    { no: 3, name: "holidays", kind: "message", T: Holiday, repeated: true },
  ],
);

/**
 * @generated from message aggregate.v1.GetRoomsTournamentsAggregateRequest
 */
export const GetRoomsTournamentsAggregateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "aggregate.v1.GetRoomsTournamentsAggregateRequest",
  [],
);

/**
 * @generated from message aggregate.v1.GetRoomsTournamentsAggregateResponse
 */
export const GetRoomsTournamentsAggregateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "aggregate.v1.GetRoomsTournamentsAggregateResponse",
  () => [
    { no: 1, name: "rooms", kind: "message", T: Room, repeated: true },
    { no: 2, name: "tournaments", kind: "message", T: Tournament, repeated: true },
  ],
);

/**
 * @generated from message aggregate.v1.GetRoomNoticesAggregateRequest
 */
export const GetRoomNoticesAggregateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "aggregate.v1.GetRoomNoticesAggregateRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message aggregate.v1.GetRoomNoticesAggregateResponse
 */
export const GetRoomNoticesAggregateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "aggregate.v1.GetRoomNoticesAggregateResponse",
  () => [
    { no: 1, name: "room", kind: "message", T: Room },
    { no: 2, name: "notices", kind: "message", T: Notice, repeated: true },
  ],
);

/**
 * @generated from message aggregate.v1.GetRoomFeesAggregateRequest
 */
export const GetRoomFeesAggregateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "aggregate.v1.GetRoomFeesAggregateRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message aggregate.v1.GetRoomFeesAggregateResponse
 */
export const GetRoomFeesAggregateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "aggregate.v1.GetRoomFeesAggregateResponse",
  () => [
    { no: 1, name: "room", kind: "message", T: Room },
    { no: 2, name: "fees", kind: "message", T: Fee, repeated: true },
  ],
);

