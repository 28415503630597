// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file base/v1/role.proto (package base.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum base.v1.RoleType
 */
export const RoleType = /*@__PURE__*/ proto3.makeEnum(
  "base.v1.RoleType",
  [
    {no: 0, name: "ROLE_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "ROLE_TYPE_COACH", localName: "COACH"},
    {no: 2, name: "ROLE_TYPE_PLAYER", localName: "PLAYER"},
    {no: 3, name: "ROLE_TYPE_ORG", localName: "ORG"},
    {no: 4, name: "ROLE_TYPE_ADMIN", localName: "ADMIN"},
  ],
);

