// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file fee/v1/fee_api.proto (package fee.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Fee } from "./fee_pb.js";

/**
 * @generated from message fee.v1.UserFee
 */
export const UserFee = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.UserFee",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "fees", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "fees_paid", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ],
);

/**
 * @generated from message fee.v1.MarkFeesRequest
 */
export const MarkFeesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.MarkFeesRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_fees", kind: "message", T: UserFee, repeated: true },
    { no: 3, name: "bill_month", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "bill_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "paid_on", kind: "message", T: Timestamp, opt: true },
    { no: 6, name: "is_late", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message fee.v1.MarkFeesResponse
 */
export const MarkFeesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.MarkFeesResponse",
  [],
);

/**
 * @generated from message fee.v1.UserMarkUnmarkFee
 */
export const UserMarkUnmarkFee = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.UserMarkUnmarkFee",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bill_month", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "bill_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "fees", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "fees_paid", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "is_late", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "paid_on", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message fee.v1.MarkAndUnmarkFeesRequest
 */
export const MarkAndUnmarkFeesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.MarkAndUnmarkFeesRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_fees", kind: "message", T: UserMarkUnmarkFee, repeated: true },
  ],
);

/**
 * @generated from message fee.v1.MarkAndUnmarkFeesResponse
 */
export const MarkAndUnmarkFeesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.MarkAndUnmarkFeesResponse",
  [],
);

/**
 * @generated from message fee.v1.ListFeesRequest
 */
export const ListFeesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.ListFeesRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message fee.v1.ListFeesResponse
 */
export const ListFeesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.ListFeesResponse",
  () => [
    { no: 1, name: "fees", kind: "message", T: Fee, repeated: true },
  ],
);

/**
 * @generated from message fee.v1.ListFeesByBillingCycleRequest
 */
export const ListFeesByBillingCycleRequest = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.ListFeesByBillingCycleRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bill_month", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "bill_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message fee.v1.ListFeesByBillingCycleResponse
 */
export const ListFeesByBillingCycleResponse = /*@__PURE__*/ proto3.makeMessageType(
  "fee.v1.ListFeesByBillingCycleResponse",
  () => [
    { no: 1, name: "fees", kind: "message", T: Fee, repeated: true },
  ],
);

