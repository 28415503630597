import { ReactNode } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGlobalStateContext } from "hooks/useGlobalState";
import { AccountDeleteRequested, DisplayError, Loading } from "components";
import { useLogout } from "hooks";
import QRCode from "react-qr-code";
import {
  Avatar,
  Box,
  Button,
  chakra,
  Flex,
  HStack,
  IconButton,
  MenuButton,
  MenuItem,
  MenuList,
  Menu,
  useColorModeValue,
  useDisclosure,
  Modal,
  Center,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  MenuGroup,
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Icon,
  SimpleGrid,
  GridItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Spacer,
  VStack,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { AiOutlineMenu, AiOutlineQrcode } from "react-icons/ai";
import {
  MdInsertInvitation,
  MdOutlinePrivacyTip,
  MdSpaceDashboard,
} from "react-icons/md";
import {
  FaArrowCircleRight,
  FaBook,
  FaChalkboardTeacher,
  FaConnectdevelop,
  FaExclamation,
} from "react-icons/fa";
import { getUserAvatar, protoUserName } from "helpers";
import { CgProfile } from "react-icons/cg";
import { IoChatboxEllipses, IoLogOutOutline } from "react-icons/io5";
import { appName } from "env";
import { BsPersonCheck, BsShareFill, BsTrophy } from "react-icons/bs";
import { Code } from "@connectrpc/connect";
import { PiStudentFill } from "react-icons/pi";
import { RoleType } from "proto/base/v1/role_pb";
import { IoIosSettings } from "react-icons/io";
import { Share } from "@capacitor/share";
import { GiMoneyStack } from "react-icons/gi";
import { NotificationCountButton } from "./NotificationCountButton";

const myLinks = [
  {
    label: "Dashboard",
    icon: <MdSpaceDashboard />,
    iconPlain: MdSpaceDashboard,
    link: "/",
    match: new RegExp(/^[/]$/g),
  },
  {
    label: "Boards",
    icon: <FaChalkboardTeacher />,
    iconPlain: FaChalkboardTeacher,
    link: "/boards",
    match: new RegExp(/[/]board/g),
  },
  {
    label: "Diary",
    icon: <FaBook />,
    iconPlain: FaBook,
    link: "/my-diary",
    match: new RegExp(/[/]my-diary/g),
  },
];

const myExternalLinks = [
  {
    label: "Batch Settings",
    icon: <IoIosSettings />,
    iconPlain: IoIosSettings,
    link: "/rooms",
    match: new RegExp(/[/]room/g),
    role: [RoleType.COACH],
  },
  {
    label: "Tournaments",
    icon: <BsTrophy />,
    iconPlain: BsTrophy,
    link: "/tournaments",
    match: new RegExp(/[/]tournaments/g),
    role: [RoleType.PLAYER, RoleType.COACH],
  },
  {
    label: "Updates",
    icon: <FaExclamation />,
    iconPlain: FaExclamation,
    link: "/my-academy-notice-board",
    match: new RegExp(/[/]my-academy-notice-board/g),
  },

  {
    label: "Attendance",
    icon: <BsPersonCheck />,
    iconPlain: BsPersonCheck,
    link: "/view-attendance",
    match: new RegExp(/[/]view-attendance/g),
    role: [RoleType.COACH],
  },
  {
    label: "My Attendance",
    icon: <BsPersonCheck />,
    iconPlain: BsPersonCheck,
    link: "/my-attendance",
    match: new RegExp(/[/]my-attendance/g),
    role: [RoleType.PLAYER],
  },
  {
    label: "My Advices",
    icon: <IoChatboxEllipses />,
    iconPlain: IoChatboxEllipses,
    link: "/my-chat",
    match: new RegExp(/[/]my-chat/g),
    role: [RoleType.PLAYER],
  },
  {
    label: "Guidance",
    icon: <IoChatboxEllipses />,
    iconPlain: IoChatboxEllipses,
    link: "/by-me-chat",
    match: new RegExp(/[/]my-chat/g),
    role: [RoleType.COACH],
  },
  // {
  //   label: "Partners",
  //   icon: <FaConnectdevelop />,
  //   iconPlain: FaConnectdevelop,
  //   link: "/my-affiliates",
  //   match: new RegExp(/[/]my-affiliates/g),
  //   role: [RoleType.COACH],
  // },
  {
    label: "Fees Management",
    icon: <GiMoneyStack />,
    iconPlain: GiMoneyStack,
    link: "/view-fees",
    match: new RegExp(/[/]view-fees/g),
    role: [RoleType.COACH],
  },
  {
    label: "Student Diaries",
    icon: <PiStudentFill />,
    iconPlain: PiStudentFill,
    link: "/my-student-diaries",
    match: new RegExp(/[/]my-student-diaries/g),
    role: [RoleType.COACH],
  },
  {
    label: "Shared with Me",
    icon: <MdInsertInvitation />,
    iconPlain: MdInsertInvitation,
    link: "/invited-rooms",
    match: new RegExp(/[/]invited-room/g),
  },

  // {
  //   label: "",
  //   // icon: <BsPersonCheck />,
  //   // iconPlain: BsPersonCheck,
  //   link: "",
  // },
];

const allLinks = [
  {
    label: "Dashboard",
    icon: <MdSpaceDashboard />,
    iconPlain: MdSpaceDashboard,
    link: "/",
    match: new RegExp(/^[/]$/g),
  },
  {
    label: "Boards",
    icon: <FaChalkboardTeacher />,
    iconPlain: FaChalkboardTeacher,
    link: "/boards",
    match: new RegExp(/[/]board/g),
  },
  {
    label: "Batch Settings",
    icon: <IoIosSettings />,
    iconPlain: IoIosSettings,
    link: "/rooms",
    match: new RegExp(/[/]room/g),
  },

  {
    label: "Diary",
    icon: <FaBook />,
    iconPlain: FaBook,
    link: "/my-diary",
    match: new RegExp(/[/]my-diary/g),
  },
  {
    label: "Students",
    icon: <PiStudentFill />,
    iconPlain: PiStudentFill,
    link: "/my-student-diaries",
    match: new RegExp(/[/]my-student-diaries/g),
  },
  {
    label: "Chat",
    icon: <IoChatboxEllipses />,
    iconPlain: IoChatboxEllipses,
    link: "/my-chat",
    match: new RegExp(/[/]my-chat/g),
  },
  {
    label: "Collabs",
    icon: <FaConnectdevelop />,
    iconPlain: FaConnectdevelop,
    link: "/my-affiliates",
    match: new RegExp(/[/]my-affiliates/g),
  },
  {
    label: "Shared with Me",
    icon: <MdInsertInvitation />,
    iconPlain: MdInsertInvitation,
    link: "/invited-rooms",
    match: new RegExp(/[/]invited-room/g),
  },
  {
    label: "Privacy Policy",
    icon: <MdOutlinePrivacyTip />,
    iconPlain: MdOutlinePrivacyTip,
    link: "https://reticulo.in/privacy",
    match: new RegExp(/^[/]$/g),
    external: true,
  },
];

const allOtherLinks = [
  {
    label: "Privacy Policy",
    icon: <MdOutlinePrivacyTip />,
    iconPlain: MdOutlinePrivacyTip,
    link: "https://reticulo.in/privacy",
    match: new RegExp(/^[/]$/g),
    external: true,
  },
];

export const MainLayout = ({ children }: { children: ReactNode }) => {
  const bg = useColorModeValue("gray.50", "gray.800");
  const sidebar = useDisclosure();
  const { loaded, data, error, isError } = useGlobalStateContext();
  const location = useLocation();
  const onLogout = useLogout();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const logoutIcon = FaArrowCircleRight;

  if (!loaded) {
    return <Loading />;
  }
  if (isError) {
    if (error?.code === Code.Unavailable) {
      return <AccountDeleteRequested />;
    }
    return <DisplayError error={error} />;
  }

  const NavItem = (props: any) => {
    const { icon, children, ...rest } = props;
    var iconColor = "brand.500";
    if (props.color !== "") {
      iconColor = props.color;
    }
    return (
      <Flex
        align="center"
        px="4"
        pl="4"
        py="2"
        cursor="pointer"
        color="inherit"
        _dark={{
          color: "gray.400",
        }}
        _hover={{
          bg: "gray.100",
          _dark: {
            bg: "gray.900",
          },
          color: "gray.900",
        }}
        role="group"
        fontWeight="semibold"
        transition=".15s ease"
        {...rest}
      >
        {icon && <Icon mx="2" fontSize="lg" as={icon} color={iconColor} />}
        {children}
      </Flex>
    );
  };

  const SidebarContent = (props: any) => (
    <Box
      as="nav"
      pos="fixed"
      top="0"
      left="0"
      zIndex="sticky"
      h="full"
      pb="10"
      overflowX="hidden"
      overflowY="auto"
      bg="white"
      _dark={{
        bg: "gray.800",
      }}
      color="inherit"
      borderRightWidth="1px"
      w="60"
      border="10px"
      {...props}
    >
      <Box px="4" py="5" justifyContent={""}>
        <Center>
          <HStack w="100%">
            <chakra.div
              w="50%"
              onClick={() => {
                sidebar.onClose();
                navigate("/profile");
              }}
            >
              <VStack bg="brand.600" py={5} borderRadius={"20%"}>
                <chakra.div pt={2} fontSize={"5xl"} textColor={"white"}>
                  <CgProfile />
                </chakra.div>
                <Text textColor={"white"}>Profile</Text>
              </VStack>
            </chakra.div>
            <Spacer />
            <chakra.div
              w="50%"
              onClick={() => {
                sidebar.onClose();
                onOpen();
              }}
            >
              <VStack bg="gray.600" py={5} borderRadius={"20%"}>
                <chakra.div pt={2} fontSize={"5xl"} textColor={"white"}>
                  <AiOutlineQrcode />
                </chakra.div>
                <Text textColor={"white"}>My QR Code</Text>
              </VStack>
            </chakra.div>
          </HStack>
        </Center>
        <Center w="100%" mt={5}>
          <Text
            bg={""}
            fontSize="2xl"
            color="black"
            _dark={{
              color: "white",
            }}
            fontWeight="semibold"
          >
            {`${protoUserName(data?.user)}`}
          </Text>
        </Center>
      </Box>
      {/* <IconButton
          ml={4}
          size="md"
          icon={<AiOutlineClose />}
          aria-label="Open Menu"
          onClick={isOpen ? onClose : onOpen}
        /> */}
      <Stack>
        <Accordion defaultIndex={[0, 1]} allowMultiple>
          <AccordionItem>
            <AccordionButton borderBottom={"2px"} borderColor={"gray.300"}>
              <Text
                _expanded={{ bg: "blue.500", color: "white" }}
                textColor={"gray.600"}
                fontWeight={"bold"}
                fontSize={"xl"}
              >
                My Personal Space
              </Text>
            </AccordionButton>
            <AccordionPanel>
              <Flex
                direction="column"
                as="nav"
                fontSize="sm"
                color="gray.600"
                aria-label="Main Navigation"
              >
                {/* {allLinks
          .filter((l) => !!data)
          .map((l) => {
            if (l.external === true) {
              return (
                <NavItem
                  icon={l.iconPlain}
                  key={l.link}
                  onClick={() => {
                    window.open(l.link);
                  }}
                >
                  <Text textColor={"brand.500"} fontSize="lg">
                    {l.label}
                  </Text>
                </NavItem>
              );
            }
            return (
              <Link to={l.link} onClick={sidebar.onClose} key={l.link}>
                <NavItem icon={l.iconPlain}>
                  <Text textColor={"brand.500"} fontSize="lg">
                    {l.label}
                  </Text>
                </NavItem>
              </Link>
            );
          })} */}
                {myLinks
                  .filter((l) => !!data)
                  .map((l) => {
                    return (
                      <Link to={l.link} onClick={sidebar.onClose} key={l.link}>
                        <NavItem icon={l.iconPlain} color={"gray.600"}>
                          <Text textColor={"gray.600"} fontSize="lg">
                            {l.label}
                          </Text>
                        </NavItem>
                      </Link>
                    );
                  })}
              </Flex>
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionButton borderBottom={"2px"} borderColor={"brand.200"}>
              <Text
                _expanded={{ bg: "blue.500", color: "white" }}
                textColor={"brand.600"}
                fontWeight="bold"
                fontSize={"xl"}
              >
                My Academy
              </Text>
            </AccordionButton>
            <AccordionPanel>
              <Flex
                direction="column"
                as="nav"
                fontSize="sm"
                color="gray.600"
                aria-label="Main Navigation"
              >
                {myExternalLinks
                  .filter((l) => !!data)
                  .filter(
                    (l) =>
                      l.role === undefined ||
                      l.role?.includes(data?.user?.roleType ?? RoleType.INVALID)
                  )
                  .map((l) => {
                    return (
                      <Link to={l.link} onClick={sidebar.onClose} key={l.link}>
                        <NavItem icon={l.iconPlain} color={"brand.600"}>
                          <Text textColor={"brand.600"} fontSize="lg">
                            {l.label}
                          </Text>
                        </NavItem>
                      </Link>
                    );
                  })}
                <Box h="2vh"></Box>
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Spacer />
        <chakra.div
          position="fixed"
          bottom={2}
          zIndex={10}
          bg="white"
          borderTop={"solid"}
          w="100%"
        >
          {allOtherLinks
            .filter((l) => !!data)
            .map((l) => {
              if (l.external === true) {
                return (
                  <NavItem
                    icon={l.iconPlain}
                    key={l.link}
                    onClick={() => {
                      window.open(l.link);
                    }}
                  >
                    <Text textColor={"brand.500"} fontSize="lg">
                      {l.label}
                    </Text>
                  </NavItem>
                );
              }
              return (
                <Link to={l.link} onClick={sidebar.onClose} key={l.link}>
                  <NavItem icon={l.iconPlain}>
                    <Text textColor={"brand.500"} fontSize="lg">
                      {l.label}
                    </Text>
                  </NavItem>
                </Link>
              );
            })}
          <Box onClick={onLogout} bg="red.100" mb="-2">
            <NavItem icon={logoutIcon} color={"red.400"}>
              <Text textColor={"red.500"} fontWeight="bold" fontSize="lg">
                Log Out{" "}
              </Text>
            </NavItem>
          </Box>
        </chakra.div>
      </Stack>
    </Box>
  );

  return (
    <Box bg={bg} overflow="hidden" minH="100vh">
      <Drawer
        isOpen={sidebar.isOpen}
        onClose={sidebar.onClose}
        placement="left"
      >
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent w="full" borderRight="none" />
        </DrawerContent>
      </Drawer>
      <React.Fragment>
        <chakra.header
          bg={"brand.500"}
          w="full"
          px={{
            base: 2,
            sm: 4,
          }}
          roundedBottom={"lg"}
          display={!location.pathname.includes("/board/") ? "block" : "none"}
        >
          <Flex
            alignItems="center"
            // justifyContent="space-between"
            mx="auto"
            h={["10vh", "7vh", "7vh"]}
          >
            <HStack display="flex" spacing={3} zIndex={500}>
              <Box
                display={{
                  base: "inline-flex",
                  lg: "none",
                }}
              >
                <IconButton
                  display={{
                    base: "flex",
                    lg: "none",
                  }}
                  aria-label="Open menu"
                  fontSize="20px"
                  color="white"
                  _dark={{
                    color: "inherit",
                  }}
                  _hover={{
                    bgColor: "",
                  }}
                  variant="filled"
                  icon={<AiOutlineMenu />}
                  onClick={sidebar.onOpen}
                />
              </Box>
              <Box display={{ base: "none", lg: "block" }}>
                <Center fontSize={["2xl", "3xl", "3xl"]} color="white">
                  {appName}
                </Center>
              </Box>

              <HStack
                display={{
                  base: "none",
                  lg: "inline-flex",
                }}
              >
                {allLinks
                  .filter((l) => !!data)
                  .map((l) => {
                    return (
                      <Button
                        key={l.link}
                        variant={l.link === location.pathname ? "outline" : ""}
                        colorScheme={
                          l.link === location.pathname ? "brand" : "transparent"
                        }
                        _hover={{
                          bgColor: "",
                        }}
                        leftIcon={l.icon}
                        size="md"
                        color={"white"}
                        px={4}
                        onClick={() => navigate(l.link)}
                      >
                        {l.label}
                      </Button>
                    );
                  })}
              </HStack>
            </HStack>
            <HStack display={{ lg: "none" }}>
              <Text fontSize={["4xl", "4xl", "4xl"]} color="gray.200" ml={2}>
                {"RADLO"}
              </Text>
            </HStack>
            <HStack
              w="100%"
              justifyContent="flex-end"
              spacing={1}
              // display={sidebar.isOpen ? "none" : "flex"}
              alignItems="flex-end"
            >
              <SimpleGrid
                columns={
                  !location.pathname.includes("/board/") ? [2, 3, 3] : [2, 3, 3]
                }
              >
                <GridItem colSpan={1}>
                  <Button
                    rounded={"3xl"}
                    onClick={() => {
                      Share.share({
                        text: appName,
                        url:
                          "https://gameplan.reticulo.in" +
                          window.location.pathname +
                          window.location.search,
                        title: appName,
                      });
                    }}
                  >
                    <chakra.span pos="relative" display="inline-block">
                      <BsShareFill size={"20"} />
                    </chakra.span>
                  </Button>
                </GridItem>
                {/* {!location.pathname.includes("/board/") &&
                  !location.pathname.includes("diary") && (
                    <GridItem colSpan={1}>
                      <Button
                        rounded={"3xl"}
                        onClick={() => {
                          navigate("/new-board");
                        }}
                      >
                        <chakra.span pos="relative" display="inline-block">
                          <AiOutlinePlusCircle size={"24"} />
                        </chakra.span>
                      </Button>
                    </GridItem>
                  )}
                {location.pathname.includes("diary") && (
                  <GridItem colSpan={1}>
                    <Button
                      rounded={"3xl"}
                      onClick={() => {
                        navigate("/new-diary");
                      }}
                    >
                      <chakra.span pos="relative" display="inline-block">
                        <AiOutlinePlusCircle size={"24"} />
                      </chakra.span>
                    </Button>
                  </GridItem>
                )} */}

                <GridItem colSpan={1}>
                  <NotificationCountButton />
                  {/* <IconButton
                    aria-label="label"
                    isRound
                    size={"lg"}
                    icon={
                      <>
                        <AiFillBell />
                        <chakra.span
                          hidden={data?.notificationsCount === 0}
                          pos="absolute"
                          top="-1px"
                          right="-1px"
                          px={2}
                          py={1}
                          fontSize="xs"
                          fontWeight="bold"
                          lineHeight="none"
                          color="red.100"
                          transform="translate(50%,-50%)"
                          bg="red.600"
                          rounded="full"
                        >
                          {data?.notificationsCount}
                        </chakra.span>
                      </>
                    }
                  /> */}
                </GridItem>
                <GridItem
                  colSpan={1}
                  display={{
                    base: "none",
                    md: "flex",
                  }}
                >
                  <Center h="full">
                    <Menu>
                      <MenuButton
                        border="solid"
                        borderColor="brand.600"
                        as={IconButton}
                        rounded={"full"}
                        variant={"link"}
                        cursor={"pointer"}
                        minW={0}
                        defaultValue={-1}
                      >
                        <Avatar
                          bg="brand.900"
                          size="xs"
                          src={getUserAvatar(data?.user?.avatarKey)}
                        />
                      </MenuButton>
                      <MenuList>
                        <MenuGroup
                          title={`Welcome ${protoUserName(data?.user)}!`}
                        >
                          <MenuItem
                            bg="white"
                            onClick={() => navigate("/profile")}
                            icon={<CgProfile />}
                          >
                            My Profile
                          </MenuItem>
                          <MenuItem onClick={onOpen} icon={<AiOutlineQrcode />}>
                            Show my QR
                          </MenuItem>
                          <MenuItem
                            onClick={onLogout}
                            icon={<IoLogOutOutline />}
                          >
                            Logout
                          </MenuItem>
                        </MenuGroup>
                      </MenuList>
                    </Menu>
                  </Center>
                </GridItem>
              </SimpleGrid>
            </HStack>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent shadow={"lg"} maxW="80vw">
                <ModalHeader>My QR Code</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Center pb={12}>
                    <QRCode value={JSON.stringify(data?.user ?? "{}")} />
                  </Center>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Flex>
        </chakra.header>
      </React.Fragment>
      {children}
    </Box>
  );
};
