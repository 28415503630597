// @generated by protoc-gen-connect-query v1.4.1 with parameter "target=ts"
// @generated from file user/v1/user_api.proto (package user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { GetPlayerProfileRequest, GetPlayerProfileResponse, GetProfileRequest, GetProfileResponse, ListAllUsersRequest, ListAllUsersResponse, RequestAccountDeleteRequest, RequestAccountDeleteResponse, UpdatePlayerProfileRequest, UpdatePlayerProfileResponse, UpdateProfileRequest, UpdateProfileResponse, UploadAvatarRequest, UploadAvatarResponse } from "./user_api_pb.js";

/**
 * @generated from rpc user.v1.UserAPI.GetProfile
 */
export const getProfile = {
  localName: "getProfile",
  name: "GetProfile",
  kind: MethodKind.Unary,
  I: GetProfileRequest,
  O: GetProfileResponse,
  service: {
    typeName: "user.v1.UserAPI"
  }
} as const;

/**
 * @generated from rpc user.v1.UserAPI.UpdateProfile
 */
export const updateProfile = {
  localName: "updateProfile",
  name: "UpdateProfile",
  kind: MethodKind.Unary,
  I: UpdateProfileRequest,
  O: UpdateProfileResponse,
  service: {
    typeName: "user.v1.UserAPI"
  }
} as const;

/**
 * @generated from rpc user.v1.UserAPI.ListAllUsers
 */
export const listAllUsers = {
  localName: "listAllUsers",
  name: "ListAllUsers",
  kind: MethodKind.Unary,
  I: ListAllUsersRequest,
  O: ListAllUsersResponse,
  service: {
    typeName: "user.v1.UserAPI"
  }
} as const;

/**
 * @generated from rpc user.v1.UserAPI.RequestAccountDelete
 */
export const requestAccountDelete = {
  localName: "requestAccountDelete",
  name: "RequestAccountDelete",
  kind: MethodKind.Unary,
  I: RequestAccountDeleteRequest,
  O: RequestAccountDeleteResponse,
  service: {
    typeName: "user.v1.UserAPI"
  }
} as const;

/**
 * @generated from rpc user.v1.UserAPI.UpdatePlayerProfile
 */
export const updatePlayerProfile = {
  localName: "updatePlayerProfile",
  name: "UpdatePlayerProfile",
  kind: MethodKind.Unary,
  I: UpdatePlayerProfileRequest,
  O: UpdatePlayerProfileResponse,
  service: {
    typeName: "user.v1.UserAPI"
  }
} as const;

/**
 * @generated from rpc user.v1.UserAPI.GetPlayerProfile
 */
export const getPlayerProfile = {
  localName: "getPlayerProfile",
  name: "GetPlayerProfile",
  kind: MethodKind.Unary,
  I: GetPlayerProfileRequest,
  O: GetPlayerProfileResponse,
  service: {
    typeName: "user.v1.UserAPI"
  }
} as const;

/**
 * @generated from rpc user.v1.UserAPI.UploadAvatar
 */
export const uploadAvatar = {
  localName: "uploadAvatar",
  name: "UploadAvatar",
  kind: MethodKind.Unary,
  I: UploadAvatarRequest,
  O: UploadAvatarResponse,
  service: {
    typeName: "user.v1.UserAPI"
  }
} as const;
