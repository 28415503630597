// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file base/v1/notifcation.proto (package base.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum base.v1.NotificationType
 */
export const NotificationType = /*@__PURE__*/ proto3.makeEnum(
  "base.v1.NotificationType",
  [
    {no: 0, name: "NOTIFICATION_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "NOTIFICATION_TYPE_ADDED_TO_ROOM", localName: "ADDED_TO_ROOM"},
    {no: 2, name: "NOTIFICATION_TYPE_REMOVED_FROM_ROOM", localName: "REMOVED_FROM_ROOM"},
    {no: 3, name: "NOTIFICATION_TYPE_BOARD_ADDED_TO_ROOM", localName: "BOARD_ADDED_TO_ROOM"},
    {no: 4, name: "NOTIFICATION_TYPE_BOARD_REMOVED_FROM_ROOM", localName: "BOARD_REMOVED_FROM_ROOM"},
    {no: 5, name: "NOTIFICATION_TYPE_PAY_FEES", localName: "PAY_FEES"},
    {no: 6, name: "NOTIFICATION_TYPE_FEES_DUE", localName: "FEES_DUE"},
  ],
);

