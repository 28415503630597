import { Button, chakra } from "@chakra-ui/react";
import { DisplayError } from "components";
import { useGlobalStateContext } from "hooks/useGlobalState";
import { AiFillBell } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export const NotificationCountButton = () => {
  const {
    notificationCountData,
    notificationError,
    isNotificationLoading,
    isNotificationError,
  } = useGlobalStateContext();

  const navigate = useNavigate();

  if (isNotificationLoading) {
    return (
      <Button rounded={"3xl"} disabled>
        <chakra.span pos="relative" display="inline-block">
          <AiFillBell size={"20"} />
        </chakra.span>
      </Button>
    );
  }
  if (isNotificationError) {
    return <DisplayError error={notificationError} />;
  }

  return (
    <Button
      rounded={"3xl"}
      onClick={() => {
        navigate("/notifications");
      }}
    >
      <chakra.span pos="relative" display="inline-block">
        <AiFillBell size={"20"} />
        <chakra.span
          hidden={notificationCountData?.count === 0}
          pos="absolute"
          top="-1px"
          right="-1px"
          px={2}
          py={1}
          fontSize="xs"
          fontWeight="bold"
          lineHeight="none"
          color="red.100"
          transform="translate(50%,-50%)"
          bg="red.600"
          rounded="full"
        >
          {notificationCountData?.count}
        </chakra.span>
      </chakra.span>
    </Button>
  );
};
