// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file base/v1/tournament_category.proto (package base.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum base.v1.TournamentCategoryType
 */
export const TournamentCategoryType = /*@__PURE__*/ proto3.makeEnum(
  "base.v1.TournamentCategoryType",
  [
    {no: 0, name: "TOURNAMENT_CATEGORY_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "TOURNAMENT_CATEGORY_TYPE_MINI", localName: "MINI"},
    {no: 2, name: "TOURNAMENT_CATEGORY_TYPE_MIDGET", localName: "MIDGET"},
    {no: 3, name: "TOURNAMENT_CATEGORY_TYPE_SUB_JUNIOR", localName: "SUB_JUNIOR"},
    {no: 4, name: "TOURNAMENT_CATEGORY_TYPE_JUNIOR", localName: "JUNIOR"},
    {no: 5, name: "TOURNAMENT_CATEGORY_TYPE_SENIOR", localName: "SENIOR"},
    {no: 6, name: "TOURNAMENT_CATEGORY_TYPE_MASTERS", localName: "MASTERS"},
  ],
);

