// @generated by protoc-gen-connect-query v1.4.1 with parameter "target=ts"
// @generated from file aggregate/v1/aggregate_api.proto (package aggregate.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { GetBoardAggregateRoomsRequest, GetBoardAggregateRoomsResponse, GetRoomFeesAggregateRequest, GetRoomFeesAggregateResponse, GetRoomNoticesAggregateRequest, GetRoomNoticesAggregateResponse, GetRoomSessionsAggregateRequest, GetRoomSessionsAggregateResponse, GetRoomsTournamentsAggregateRequest, GetRoomsTournamentsAggregateResponse, GetUserAggregateProfileRequest, GetUserAggregateProfileResponse } from "./aggregate_api_pb.js";

/**
 * @generated from rpc aggregate.v1.AggregateAPI.GetUserAggregateProfile
 */
export const getUserAggregateProfile = {
  localName: "getUserAggregateProfile",
  name: "GetUserAggregateProfile",
  kind: MethodKind.Unary,
  I: GetUserAggregateProfileRequest,
  O: GetUserAggregateProfileResponse,
  service: {
    typeName: "aggregate.v1.AggregateAPI"
  }
} as const;

/**
 * @generated from rpc aggregate.v1.AggregateAPI.GetBoardAggregateRooms
 */
export const getBoardAggregateRooms = {
  localName: "getBoardAggregateRooms",
  name: "GetBoardAggregateRooms",
  kind: MethodKind.Unary,
  I: GetBoardAggregateRoomsRequest,
  O: GetBoardAggregateRoomsResponse,
  service: {
    typeName: "aggregate.v1.AggregateAPI"
  }
} as const;

/**
 * @generated from rpc aggregate.v1.AggregateAPI.GetRoomSessionsAggregate
 */
export const getRoomSessionsAggregate = {
  localName: "getRoomSessionsAggregate",
  name: "GetRoomSessionsAggregate",
  kind: MethodKind.Unary,
  I: GetRoomSessionsAggregateRequest,
  O: GetRoomSessionsAggregateResponse,
  service: {
    typeName: "aggregate.v1.AggregateAPI"
  }
} as const;

/**
 * @generated from rpc aggregate.v1.AggregateAPI.GetRoomsTournamentsAggregate
 */
export const getRoomsTournamentsAggregate = {
  localName: "getRoomsTournamentsAggregate",
  name: "GetRoomsTournamentsAggregate",
  kind: MethodKind.Unary,
  I: GetRoomsTournamentsAggregateRequest,
  O: GetRoomsTournamentsAggregateResponse,
  service: {
    typeName: "aggregate.v1.AggregateAPI"
  }
} as const;

/**
 * @generated from rpc aggregate.v1.AggregateAPI.GetRoomNoticesAggregate
 */
export const getRoomNoticesAggregate = {
  localName: "getRoomNoticesAggregate",
  name: "GetRoomNoticesAggregate",
  kind: MethodKind.Unary,
  I: GetRoomNoticesAggregateRequest,
  O: GetRoomNoticesAggregateResponse,
  service: {
    typeName: "aggregate.v1.AggregateAPI"
  }
} as const;

/**
 * @generated from rpc aggregate.v1.AggregateAPI.GetRoomFeesAggregate
 */
export const getRoomFeesAggregate = {
  localName: "getRoomFeesAggregate",
  name: "GetRoomFeesAggregate",
  kind: MethodKind.Unary,
  I: GetRoomFeesAggregateRequest,
  O: GetRoomFeesAggregateResponse,
  service: {
    typeName: "aggregate.v1.AggregateAPI"
  }
} as const;
