import {
  Button,
  Card,
  CardHeader,
  Center,
  chakra,
  Flex,
  GridItem,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  SimpleGrid,
  Table,
  Td,
  Text,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { BsSave2 } from "react-icons/bs";

export default function AggregatedFeesAnalysis() {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let years: number[] = [];
  for (let i = 0; i < 30; i++) {
    years.push(2025 + i);
  }
  const sampleDefaulterList = [
    {
      name: "Sample 1",
      lastPaidDate: "01-01-2024",
      monthsUnpaid: 12,
      amountDue: 25600,
      batch: "Batch 1",
    },
    {
      name: "Sample 2",
      lastPaidDate: "01-09-2024",
      monthsUnpaid: 3,
      amountDue: 12000,
      batch: "Batch 2",
    },
    {
      name: "Sample 3",
      lastPaidDate: "21-12-2024",
      monthsUnpaid: 1,
      amountDue: 2000,
      batch: "Batch 3",
    },
  ];
  let totalAmountDue = 0;
  let totalMonthsUnpaid = 0;
  const {
    isOpen: defaulterListIsOpen,
    onOpen: defaulterListOnOpen,
    onClose: defaulterListOnClose,
  } = useDisclosure();

  sampleDefaulterList.map((user) => {
    totalAmountDue += user.amountDue;
    totalMonthsUnpaid += user.monthsUnpaid;
  });
  const quarters = ["Q1", "Q2", "Q3", "Q4"];
  const batchDetails = [
    {
      name: "Batch Name 1",
      expectedIncome: 40000,
      actualIncome: 28000,
    },
    {
      name: "Batch Name 2",
      expectedIncome: 40000,
      actualIncome: 18000,
    },
    {
      name: "Batch Name 3",
      expectedIncome: 40000,
      actualIncome: 40000,
    },
    {
      name: "Batch Name 4",
      expectedIncome: 40000,
      actualIncome: 28000,
    },
    {
      name: "Batch Name 5",
      expectedIncome: 40000,
      actualIncome: 25000,
    },
  ];

  return (
    <chakra.div>
      <Modal
        isOpen={defaulterListIsOpen}
        onClose={defaulterListOnClose}
        size={"full"}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent minW="30%" mt={"10%"}>
          <ModalHeader>
            <VStack>
              <Heading size={"lg"}>Aggregate Defaulter List</Heading>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody h="100%" w="100%">
            <chakra.div minH="60vh">
              <SimpleGrid
                columns={20}
                py={4}
                overflow={"scroll"}
                fontSize={"sm"}
              >
                <GridItem
                  rowSpan={1}
                  colSpan={6}
                  border={"solid"}
                  borderWidth={"0.5px"}
                >
                  <Flex px={1} h="100%" alignItems={"flex-end"}>
                    <Text textAlign="center" w="100%">
                      Name
                    </Text>
                  </Flex>
                </GridItem>
                <GridItem
                  colSpan={4}
                  textAlign={"center"}
                  border={"solid"}
                  borderLeft={"none"}
                  borderWidth={"0.5px"}
                >
                  <Flex px={1} h="100%" alignItems={"flex-end"}>
                    <Text>Batch Name</Text>
                  </Flex>
                </GridItem>
                <GridItem
                  colSpan={3}
                  textAlign={"center"}
                  border={"solid"}
                  borderLeft={"none"}
                  borderWidth={"0.5px"}
                >
                  <Flex px={1} h="100%" alignItems={"flex-end"}>
                    <Text>Last Paid Date</Text>
                  </Flex>
                </GridItem>
                <GridItem
                  colSpan={3}
                  textAlign={"center"}
                  border={"solid"}
                  borderLeft={"none"}
                  borderWidth={"0.5px"}
                >
                  <Flex px={1} h="100%" alignItems={"flex-end"}>
                    <Text>Months Unpaid</Text>
                  </Flex>
                </GridItem>
                <GridItem
                  colSpan={4}
                  textAlign={"center"}
                  border={"solid"}
                  borderLeft={"none"}
                  borderWidth={"0.5px"}
                >
                  <Flex px={1} h="100%" alignItems={"flex-end"}>
                    <Text>Amount Due</Text>
                  </Flex>
                </GridItem>
                {sampleDefaulterList.map((defaulter) => {
                  return (
                    <>
                      <GridItem
                        fontSize={"sm"}
                        colSpan={6}
                        textAlign={"center"}
                        border={"solid"}
                        borderTop={"none"}
                        borderWidth={"0.5px"}
                      >
                        {defaulter.name}
                      </GridItem>
                      <GridItem
                        fontSize={"sm"}
                        colSpan={4}
                        textAlign={"center"}
                        border={"solid"}
                        borderLeft={"none"}
                        borderTop={"none"}
                        borderWidth={"0.5px"}
                      >
                        {defaulter.batch}
                      </GridItem>
                      <GridItem
                        fontSize={"sm"}
                        colSpan={3}
                        textAlign={"center"}
                        border={"solid"}
                        borderLeft={"none"}
                        borderTop={"none"}
                        borderWidth={"0.5px"}
                      >
                        {defaulter.lastPaidDate}
                      </GridItem>
                      <GridItem
                        fontSize={"sm"}
                        colSpan={3}
                        textAlign={"center"}
                        border={"solid"}
                        borderLeft={"none"}
                        borderTop={"none"}
                        borderWidth={"0.5px"}
                      >
                        {defaulter.monthsUnpaid}
                      </GridItem>
                      <GridItem
                        colSpan={4}
                        fontSize={"sm"}
                        textAlign={"center"}
                        border={"solid"}
                        borderLeft={"none"}
                        borderTop={"none"}
                        borderWidth={"0.5px"}
                      >
                        {defaulter.amountDue}
                      </GridItem>
                    </>
                  );
                })}
              </SimpleGrid>
            </chakra.div>
            <SimpleGrid columns={20} py={4} maxH="60vh" overflow={"scroll"}>
              <GridItem
                rowSpan={1}
                colSpan={10}
                border={"solid"}
                borderWidth={"2px"}
                h="100%"
                pl={1}
              >
                <Text fontSize={"lg"} fontWeight={"bold"}>
                  Total
                </Text>
              </GridItem>

              <GridItem
                rowSpan={1}
                colSpan={5}
                border={"solid"}
                borderWidth={"2px"}
                borderLeft={"none"}
                h="100%"
                pl={1}
              >
                <Text fontSize={"lg"} fontWeight={"bold"} textAlign={"center"}>
                  {totalMonthsUnpaid}
                </Text>
              </GridItem>
              <GridItem
                rowSpan={1}
                colSpan={5}
                border={"solid"}
                borderWidth={"2px"}
                borderLeft={"none"}
                h="100%"
                pl={1}
              >
                <Text fontSize={"lg"} fontWeight={"bold"} textAlign={"center"}>
                  ₹ {totalAmountDue}
                </Text>
              </GridItem>
            </SimpleGrid>
            <Button
              // zIndex={1000}
              position="fixed"
              bottom={5}
              right={5}
              colorScheme="green"
              onClick={() => {
                defaulterListOnClose();
              }}
            >
              <HStack>
                <BsSave2 />
                <Text ml={1}>Mark Paid Fees</Text>
              </HStack>
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Center py={4}>
        <VStack>
          <Heading variant={"lg"}>Aggregated Fees Analysis</Heading>
          <SimpleGrid columns={4} pt={4} spacingX={2} w="90%">
            <Select w="100%">
              {quarters.map((month) => {
                return <option>{month}</option>;
              })}
            </Select>
            <Select w="100%">
              {months.map((month) => {
                return <option>{month}</option>;
              })}
            </Select>
            <GridItem colSpan={2}>
              <Select w="100%">
                {years.map((month) => {
                  return <option>{month}</option>;
                })}
              </Select>
            </GridItem>
          </SimpleGrid>
          <SimpleGrid columns={4} w="90%" pt={2} spacing={2}>
            <Card shadow={"lg"} bg="blue.100">
              <CardHeader>
                <Center w="100%">
                  <Heading size={"2xl"} color={"blue"} textAlign={"center"}>
                    {"64%"}
                  </Heading>
                </Center>
                <Center>
                  <Text
                    textAlign={"center"}
                    // whiteSpace={"pre-line"}
                    fontSize={"sm"}
                  >
                    {"Fees \n collected"}
                  </Text>
                </Center>
              </CardHeader>
            </Card>
            <GridItem colSpan={3} h="100%">
              <Card shadow={"lg"} bg="purple.200" h="100%">
                <CardHeader>
                  <Center w="100%">
                    <Heading
                      size={"2xl"}
                      color={"purple.900"}
                      textAlign={"center"}
                    >
                      {"₹ " + 128000}
                    </Heading>
                  </Center>
                  <Center>
                    <Text
                      textAlign={"center"}
                      whiteSpace={"pre-line"}
                      fontSize={"sm"}
                    >
                      {"Total Estimated Income\n ₹ 200000"}
                    </Text>
                  </Center>
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem colSpan={4} rowSpan={2}>
              <Card shadow={"lg"} h="100%">
                <CardHeader>
                  <Center>
                    <Heading size={"md"}>Batch Wise Collection</Heading>
                  </Center>
                  <Table variant="simple" fontSize={"sm"} size={"sm"}>
                    <Tr>
                      <Td>Name</Td>
                      <Td>Exp.</Td>
                      <Td>Act.</Td>
                      <Td>%</Td>
                    </Tr>
                    {batchDetails.map((batch) => {
                      return (
                        <>
                          <Tr>
                            <Td>{batch.name}</Td>
                            <Td>{batch.expectedIncome}</Td>
                            <Td>{batch.actualIncome}</Td>
                            <Td>
                              {(batch.actualIncome / batch.expectedIncome) *
                                100}
                            </Td>
                          </Tr>
                        </>
                      );
                    })}
                  </Table>
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem colSpan={2}>
              <Card
                shadow={"lg"}
                bg="red.200"
                onClick={() => defaulterListOnOpen()}
              >
                <CardHeader>
                  <Center w="100%">
                    <Heading size={"4xl"} color={"red"} textAlign={"center"}>
                      {28}
                    </Heading>
                  </Center>
                  <Center>
                    <Text
                      textAlign={"center"}
                      // whiteSpace={"pre-line"}
                      fontSize={"sm"}
                    >
                      {"Defaulters"}
                    </Text>
                  </Center>
                </CardHeader>
              </Card>
            </GridItem>
          </SimpleGrid>
        </VStack>
      </Center>
    </chakra.div>
  );
}
