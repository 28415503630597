// @generated by protoc-gen-connect-query v1.4.1 with parameter "target=ts"
// @generated from file auth/v1/auth.proto (package auth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { ChangePasswordRequest, ChangePasswordResponse, LoginRequest, LoginResponse, LogoutRequest, LogoutResponse, RegisterRequest, RegisterResponse, SetFCMTokenRequest, SetFCMTokenResponse, VerifySessionRequest, VerifySessionResponse } from "./auth_pb.js";

/**
 * Login allows users to login
 *
 * @generated from rpc auth.v1.AuthAPI.Login
 */
export const login = {
  localName: "login",
  name: "Login",
  kind: MethodKind.Unary,
  I: LoginRequest,
  O: LoginResponse,
  service: {
    typeName: "auth.v1.AuthAPI"
  }
} as const;

/**
 * Register allows users to Register
 *
 * @generated from rpc auth.v1.AuthAPI.Register
 */
export const register = {
  localName: "register",
  name: "Register",
  kind: MethodKind.Unary,
  I: RegisterRequest,
  O: RegisterResponse,
  service: {
    typeName: "auth.v1.AuthAPI"
  }
} as const;

/**
 * ChangePassword allows users to change their password
 *
 * @generated from rpc auth.v1.AuthAPI.ChangePassword
 */
export const changePassword = {
  localName: "changePassword",
  name: "ChangePassword",
  kind: MethodKind.Unary,
  I: ChangePasswordRequest,
  O: ChangePasswordResponse,
  service: {
    typeName: "auth.v1.AuthAPI"
  }
} as const;

/**
 * VerifySession allows a user to verify a valid session
 *
 * @generated from rpc auth.v1.AuthAPI.VerifySession
 */
export const verifySession = {
  localName: "verifySession",
  name: "VerifySession",
  kind: MethodKind.Unary,
  I: VerifySessionRequest,
  O: VerifySessionResponse,
  service: {
    typeName: "auth.v1.AuthAPI"
  }
} as const;

/**
 * Logs out a user from session
 *
 * @generated from rpc auth.v1.AuthAPI.Logout
 */
export const logout = {
  localName: "logout",
  name: "Logout",
  kind: MethodKind.Unary,
  I: LogoutRequest,
  O: LogoutResponse,
  service: {
    typeName: "auth.v1.AuthAPI"
  }
} as const;

/**
 * @generated from rpc auth.v1.AuthAPI.SetFCMToken
 */
export const setFCMToken = {
  localName: "setFCMToken",
  name: "SetFCMToken",
  kind: MethodKind.Unary,
  I: SetFCMTokenRequest,
  O: SetFCMTokenResponse,
  service: {
    typeName: "auth.v1.AuthAPI"
  }
} as const;
