// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file base/v1/tournament_level.proto (package base.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from enum base.v1.TournamentLevelType
 */
export const TournamentLevelType = /*@__PURE__*/ proto3.makeEnum(
  "base.v1.TournamentLevelType",
  [
    {no: 0, name: "TOURNAMENT_LEVEL_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "TOURNAMENT_LEVEL_TYPE_AMATEUR", localName: "AMATEUR"},
    {no: 2, name: "TOURNAMENT_LEVEL_TYPE_DISTRICT", localName: "DISTRICT"},
    {no: 3, name: "TOURNAMENT_LEVEL_TYPE_STATE_ZONAL", localName: "STATE_ZONAL"},
    {no: 4, name: "TOURNAMENT_LEVEL_TYPE_STATE", localName: "STATE"},
    {no: 5, name: "TOURNAMENT_LEVEL_TYPE_NATIONAL_ZONAL", localName: "NATIONAL_ZONAL"},
    {no: 6, name: "TOURNAMENT_LEVEL_TYPE_NATIONAL", localName: "NATIONAL"},
    {no: 7, name: "TOURNAMENT_LEVEL_TYPE_CONTINENTAL", localName: "CONTINENTAL"},
    {no: 8, name: "TOURNAMENT_LEVEL_TYPE_WORLD", localName: "WORLD"},
  ],
);

