// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file auth/v1/auth.proto (package auth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ChangePasswordRequest, ChangePasswordResponse, LoginRequest, LoginResponse, LogoutRequest, LogoutResponse, RegisterRequest, RegisterResponse, SetFCMTokenRequest, SetFCMTokenResponse, VerifySessionRequest, VerifySessionResponse } from "./auth_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * AuthAPI service. Provides auth related services.
 *
 * @generated from service auth.v1.AuthAPI
 */
export const AuthAPI = {
  typeName: "auth.v1.AuthAPI",
  methods: {
    /**
     * Login allows users to login
     *
     * @generated from rpc auth.v1.AuthAPI.Login
     */
    login: {
      name: "Login",
      I: LoginRequest,
      O: LoginResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Register allows users to Register
     *
     * @generated from rpc auth.v1.AuthAPI.Register
     */
    register: {
      name: "Register",
      I: RegisterRequest,
      O: RegisterResponse,
      kind: MethodKind.Unary,
    },
    /**
     * ChangePassword allows users to change their password
     *
     * @generated from rpc auth.v1.AuthAPI.ChangePassword
     */
    changePassword: {
      name: "ChangePassword",
      I: ChangePasswordRequest,
      O: ChangePasswordResponse,
      kind: MethodKind.Unary,
    },
    /**
     * VerifySession allows a user to verify a valid session
     *
     * @generated from rpc auth.v1.AuthAPI.VerifySession
     */
    verifySession: {
      name: "VerifySession",
      I: VerifySessionRequest,
      O: VerifySessionResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Logs out a user from session
     *
     * @generated from rpc auth.v1.AuthAPI.Logout
     */
    logout: {
      name: "Logout",
      I: LogoutRequest,
      O: LogoutResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc auth.v1.AuthAPI.SetFCMToken
     */
    setFCMToken: {
      name: "SetFCMToken",
      I: SetFCMTokenRequest,
      O: SetFCMTokenResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

