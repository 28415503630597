import {
  Box,
  Button,
  Card,
  CardHeader,
  Center,
  chakra,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  SimpleGrid,
  Table,
  Td,
  Text,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
// import { useQuery } from '@tanstack/react-query';
import { useMutation, useQuery } from "@connectrpc/connect-query";
import { DisplayError, Loading } from "components";
import { addMonths, eachMonthOfInterval, format, startOfMonth } from "date-fns";
import { formatFees, protoUserName } from "helpers";
import { getRoomFeesAggregate } from "proto/aggregate/v1/aggregate_api-AggregateAPI_connectquery";
import { Fee } from "proto/fee/v1/fee_pb";
import { RoomUser } from "proto/room/v1/room_pb";
import { useEffect, useMemo, useState } from "react";
import { FaMarker } from "react-icons/fa";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { MdMoneyOff, MdOutlineDisabledByDefault } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { IoMdMore } from "react-icons/io";
import { EditIcon } from "@chakra-ui/icons";
import { BiSearch } from "react-icons/bi";
import { BsSave2 } from "react-icons/bs";
import { GetRoomFeesAggregateResponse } from "proto/aggregate/v1/aggregate_api_pb";
import { UserMarkUnmarkFee } from "proto/fee/v1/fee_api_pb";
import { markAndUnmarkFees } from "proto/fee/v1/fee_api-FeeAPI_connectquery";
import { Timestamp } from "@bufbuild/protobuf";
import { Controller, useForm } from "react-hook-form";
import {
  updateRoomEntityCustomFee,
  updateRoomFeeSetting,
} from "proto/room/v1/room_api-RoomAPI_connectquery";

const useGetRoom = (roomId: string) => {
  const { isLoading, isError, error, data, refetch } = useQuery(
    getRoomFeesAggregate,
    {
      roomId,
    }
  );

  return {
    isLoading: isLoading,
    isError,
    error,
    data,
    refetch,
  };
};

const UserFees = ({
  defaultFees,
  user,
  fees,
  onManage,
}: {
  defaultFees: bigint;
  user: RoomUser;
  fees: Fee[];
  onManage: () => void;
}) => {
  const [showFees, setShowFees] = useState(true);

  const monthsList = useMemo(() => {
    const start = addMonths(startOfMonth(new Date()), 1);
    const end = addMonths(start, -11);
    return eachMonthOfInterval({
      start,
      end,
    }).reverse();
  }, []);

  return (
    <Card w="80%" borderRadius={"sm"} shadow={"md"}>
      <CardHeader py={2}>
        <SimpleGrid columns={5}>
          <GridItem colSpan={3} rowSpan={2}>
            <Text fontSize={"lg"}>
              {"" + user.user?.firstName + " " + user.user?.lastName}
            </Text>
          </GridItem>
          <GridItem
            colSpan={2}
            onClick={() => {
              setShowFees(!showFees);
            }}
            w="100%"
            alignItems={"end"}
            rowSpan={2}
          >
            <Flex
              justify={"flex-end"}
              align="center"
              border="solid"
              borderColor={"transparent"}
            >
              {showFees ? (
                <Text fontSize={"xl"}>
                  <MdMoneyOff />
                </Text>
              ) : (
                <Text
                  whiteSpace={"pre-line"}
                  align={"right"}
                  fontSize={"sm"}
                  verticalAlign={"center"}
                >
                  {formatFees(user?.customFees ?? defaultFees)}
                  /mo
                </Text>
              )}
            </Flex>
          </GridItem>
        </SimpleGrid>
        <Box mt={4} mb={2} w="100%">
          <Text>Fees History</Text>
          <SimpleGrid columns={12} spacing={0}>
            {monthsList.map((item) => {
              const fee = fees.find(
                (fee) =>
                  fee.billMonth === item.getMonth() + 1 &&
                  fee.billYear === item.getFullYear()
              );
              var color = "";
              if (fee) {
                color =
                  fee.feesPaid >= fee.fees && !fee.isLate
                    ? "green.500"
                    : fee.feesPaid && fee.isLate
                    ? "orange"
                    : "red.500";
              }

              return (
                <GridItem>
                  <Box h={1} bg={color} />
                  <Text
                    fontSize={"10"}
                    textAlign={"center"}
                    verticalAlign={"center"}
                    h="100%"
                  >
                    {format(item, "MMM")}
                  </Text>
                </GridItem>
              );
            })}
          </SimpleGrid>
        </Box>
        <SimpleGrid columns={1} w="100%" spacing={2}>
          <GridItem w="100%">
            <Button colorScheme="facebook" w="100%" onClick={onManage}>
              Manage
            </Button>
          </GridItem>
        </SimpleGrid>
      </CardHeader>
    </Card>
  );
};

interface FeePaymentData {
  user: RoomUser;
  feesPaidMonths: Set<number>;
}

const MarkFees = ({
  data,
  refetch,
}: {
  data?: GetRoomFeesAggregateResponse;
  refetch: () => void;
}) => {
  const months = [
    ["Jan", "Feb", "Mar"],
    ["Apr", "May", "Jun"],
    ["Jul", "Aug", "Sep"],
    ["Oct", "Nov", "Dec"],
  ];
  const quarters = ["Q1", "Q2", "Q3", "Q4"];
  const [year, setYear] = useState(() => new Date().getFullYear());
  const [feePaymentData, setFeePaymentData] = useState<FeePaymentData[]>([]);

  useEffect(() => {
    const feePaymentData: FeePaymentData[] = [];
    data?.room?.users?.forEach((user) => {
      const feePaymentDataIndex = feePaymentData.findIndex(
        (data) => data.user.user?.id === user.user?.id
      );
      if (feePaymentDataIndex === -1) {
        feePaymentData.push({
          user: user,
          feesPaidMonths: new Set<number>(),
        });
      }
      const fees = data?.fees?.filter(
        (fee) => fee.user?.id === user.user?.id && fee.billYear === year
      );

      fees.forEach((fee) => {
        if (fee.feesPaid > 0) {
          const feePaymentDataIndex = feePaymentData.findIndex(
            (data) => data.user.user?.id === fee.user?.id
          );
          if (feePaymentDataIndex === -1) {
            feePaymentData.push({
              user: user,
              feesPaidMonths: new Set<number>([fee.billMonth]),
            });
          } else {
            feePaymentData[feePaymentDataIndex].feesPaidMonths.add(
              fee.billMonth
            );
          }
        }
      });
    });
    setFeePaymentData(feePaymentData);
  }, [data, year]);

  const toast = useToast();

  const { mutate, isPending } = useMutation(markAndUnmarkFees, {
    onSuccess: (data) => {
      toast({
        title: "Changes Saved",
        description: "",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    },
    onError: (err) => {
      toast({
        title: "Failed to save changes",
        description: err.message,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    },
    onSettled: () => {
      refetch();
    },
  });

  return (
    <>
      <Select
        w="100%"
        value={year}
        onChange={(e) => setYear(Number.parseInt(e.target.value))}
      >
        <option value={new Date().getFullYear() + 1}>
          {new Date().getFullYear() + 1}
        </option>
        {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i)
          .filter((v) => v > 2020)
          .map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
      </Select>
      <InputGroup size={"sm"}>
        <Input w="100%" placeholder="Search Name/ID"></Input>
        <InputRightAddon onClick={() => {}}>{<BiSearch />}</InputRightAddon>
      </InputGroup>
      <SimpleGrid columns={18} py={4}>
        <GridItem
          rowSpan={2}
          colSpan={6}
          border={"solid"}
          borderWidth={"0.5px"}
          h="100%"
        >
          <Flex px={1} h="100%" alignItems={"flex-end"}>
            <Text textAlign="center" w="100%">
              Name
            </Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={12}>
          <Text
            textAlign={"center"}
            border={"solid"}
            borderLeft={"none"}
            borderWidth={"0.5px"}
          >
            Months
          </Text>
        </GridItem>

        <GridItem
          colSpan={3}
          border={"solid"}
          borderTop="none"
          borderLeft={"none"}
          borderWidth={"0.5px"}
        >
          <Text fontSize={"sm"} h="100%" textAlign={"center"}>
            {"Quarter"}
          </Text>
        </GridItem>
        <GridItem
          colSpan={3}
          border={"solid"}
          borderTop="none"
          borderLeft={"none"}
          borderWidth={"0.5px"}
        >
          <Text fontSize={"sm"} h="100%" textAlign={"center"}>
            {"Month 1"}
          </Text>
        </GridItem>
        <GridItem
          colSpan={3}
          border={"solid"}
          borderTop="none"
          borderLeft={"none"}
          borderWidth={"0.5px"}
        >
          <Text fontSize={"sm"} h="100%" textAlign={"center"}>
            {"Month 2"}
          </Text>
        </GridItem>
        <GridItem
          colSpan={3}
          border={"solid"}
          borderTop="none"
          borderLeft={"none"}
          borderWidth={"0.5px"}
        >
          <Text fontSize={"sm"} h="100%" textAlign={"center"}>
            {"Month 3"}
          </Text>
        </GridItem>

        {/* {months.map((month) => {
                    return (
                      <GridItem
                        border={"solid"}
                        borderTop={"none"}
                        borderLeft={"none"}
                        borderWidth={"0.5px"}
                      >
                        <Text
                          textAlign={"center"}
                          pt={1}
                          fontSize={"9"}
                          // style={{ transform: "rotate(-60deg)" }}
                        >
                          {month}
                        </Text>
                      </GridItem>
                    );
                  })} */}

        {data?.room?.users.map((item) => {
          return (
            <>
              <GridItem
                rowSpan={4}
                colSpan={6}
                border={"solid"}
                borderTop={"none"}
                borderWidth={"0.5px"}
                px={2}
                fontSize={"sm"}
              >
                {item.user?.firstName + " " + item.user?.lastName}
              </GridItem>
              {quarters.map((quarter, quarterIndex) => {
                const paidForQuarter =
                  Array.from(
                    feePaymentData.find((feePayment) => {
                      return feePayment.user?.user?.id === item.user?.id;
                    })?.feesPaidMonths ?? new Set<number>()
                  ).filter((month: number) => {
                    return (
                      month === 1 + 3 * quarterIndex ||
                      month === 2 + 3 * quarterIndex ||
                      month === 3 + 3 * quarterIndex
                    );
                  }).length === 3;

                return (
                  <>
                    <GridItem
                      fontSize={"sm"}
                      colSpan={3}
                      border={"solid"}
                      borderTop="none"
                      borderLeft={"none"}
                      borderWidth={"0.5px"}
                    >
                      <Checkbox
                        h="100%"
                        w="100%"
                        pl={1}
                        isChecked={paidForQuarter}
                        onChange={(e) => {
                          const feePaymentDataIndex = feePaymentData.findIndex(
                            (data) => data.user.user?.id === item.user?.id
                          );
                          if (e.target.checked) {
                            feePaymentData[
                              feePaymentDataIndex
                            ].feesPaidMonths.add(1 + 3 * quarterIndex);
                            feePaymentData[
                              feePaymentDataIndex
                            ].feesPaidMonths.add(2 + 3 * quarterIndex);
                            feePaymentData[
                              feePaymentDataIndex
                            ].feesPaidMonths.add(3 + 3 * quarterIndex);
                          } else {
                            feePaymentData[
                              feePaymentDataIndex
                            ].feesPaidMonths.delete(1 + 3 * quarterIndex);
                            feePaymentData[
                              feePaymentDataIndex
                            ].feesPaidMonths.delete(2 + 3 * quarterIndex);
                            feePaymentData[
                              feePaymentDataIndex
                            ].feesPaidMonths.delete(3 + 3 * quarterIndex);
                          }
                          setFeePaymentData([...feePaymentData]);
                        }}
                      >
                        <Text textAlign={"center"} fontSize={"sm"}>
                          {quarter}
                        </Text>
                      </Checkbox>
                    </GridItem>
                    {months[quarterIndex].map((month, mIndex) => {
                      const fee = data.fees.find(
                        (fee) =>
                          fee.user?.id === item.user?.id &&
                          fee.billYear === year &&
                          fee.billMonth === mIndex + 3 * quarterIndex + 1
                      );
                      var color = "";
                      if (fee) {
                        color =
                          fee.feesPaid >= fee.fees && !fee.isLate
                            ? "green.300"
                            : fee.feesPaid && fee.isLate
                            ? "orange.300"
                            : "red.300";
                      }

                      return (
                        <GridItem
                          colSpan={3}
                          border={"solid"}
                          borderTop="none"
                          borderLeft={"none"}
                          borderWidth={"0.5px"}
                          bgColor={color}
                        >
                          <Checkbox
                            h="100%"
                            pl={1}
                            w="100%"
                            isChecked={feePaymentData
                              .find(
                                (data) => data.user.user?.id === item.user?.id
                              )
                              ?.feesPaidMonths.has(
                                mIndex + 3 * quarterIndex + 1
                              )}
                            onChange={(e) => {
                              const feePaymentDataIndex =
                                feePaymentData.findIndex(
                                  (data) => data.user.user?.id === item.user?.id
                                );
                              if (e.target.checked) {
                                feePaymentData[
                                  feePaymentDataIndex
                                ].feesPaidMonths.add(
                                  mIndex + 3 * quarterIndex + 1
                                );
                              } else {
                                feePaymentData[
                                  feePaymentDataIndex
                                ].feesPaidMonths.delete(
                                  mIndex + 3 * quarterIndex + 1
                                );
                              }
                              setFeePaymentData([...feePaymentData]);
                            }}
                          >
                            <Text textAlign={"center"} fontSize={"sm"}>
                              {month}
                            </Text>
                          </Checkbox>
                        </GridItem>
                      );
                    })}
                  </>
                );
              })}
              {/* {months.map((month) => {
                          return (
                            <GridItem
                              border={"solid"}
                              borderTop={"none"}
                              borderLeft={"none"}
                              borderWidth={"0.5px"}
                            >
                              <Flex
                                w="100%"
                                alignItems={"center"}
                                my={1}
                                textAlign={"center"}
                              >
                                <Center w="100%">
                                  <Checkbox></Checkbox>
                                </Center>
                              </Flex>
                            </GridItem>
                          );
                        })} */}
            </>
          );
        })}
      </SimpleGrid>
      <Button
        zIndex={1000}
        position="fixed"
        bottom={5}
        right={5}
        colorScheme="green"
        isLoading={isPending}
        onClick={() => {
          const userFees: UserMarkUnmarkFee[] = [];
          feePaymentData.forEach((feedata) => {
            const fees =
              data?.fees.filter(
                (fee) =>
                  fee.user?.id === feedata.user.user?.id &&
                  fee.billYear === year
              ) ?? [];

            feedata.feesPaidMonths.forEach((month) => {
              const existingFee = fees.find((fee) => fee.billMonth === month);
              if (!existingFee) {
                userFees.push(
                  new UserMarkUnmarkFee({
                    userId: feedata.user.user?.id,
                    fees:
                      feedata.user.customFees ??
                      data?.room?.defaultFees ??
                      BigInt(0),
                    feesPaid:
                      feedata.user.customFees ??
                      data?.room?.defaultFees ??
                      BigInt(0),
                    billMonth: month,
                    billYear: year,
                    isLate: false,
                    paidOn: Timestamp.now(),
                  })
                );
              } else {
                userFees.push(
                  new UserMarkUnmarkFee({
                    fees: existingFee.fees,
                    userId: feedata.user.user?.id,
                    feesPaid: existingFee.fees,
                    billMonth: month,
                    billYear: year,
                    isLate: existingFee.dueDate
                      ? existingFee.dueDate.toDate() < new Date()
                      : false,
                    paidOn: Timestamp.now(),
                  })
                );
              }
            });

            fees.forEach((fee) => {
              if (!feedata.feesPaidMonths.has(fee.billMonth)) {
                userFees.push(
                  new UserMarkUnmarkFee({
                    fees: fee.fees,
                    userId: feedata.user.user?.id,
                    feesPaid: BigInt(0),
                    billMonth: fee.billMonth,
                    billYear: year,
                    isLate: false,
                  })
                );
              }
            });
          });

          if (userFees.length > 0) {
            mutate({
              userFees,
              roomId: data?.room?.id,
            });
          }
        }}
      >
        <HStack>
          <BsSave2 />
          <Text ml={1}>Save Changes</Text>
        </HStack>
      </Button>
    </>
  );
};

interface FeeSettingsForm {
  defaultFees: number;
  billingCycleDay: number;
}

const FeeSettings = ({
  data,
  refetch,
}: {
  data?: GetRoomFeesAggregateResponse;
  refetch: () => void;
}) => {
  const {
    register,
    handleSubmit,
    // watch,
    control,
    formState: { errors },
  } = useForm<FeeSettingsForm>({
    defaultValues: {
      defaultFees: Number(data?.room?.defaultFees ?? BigInt(0)),
      billingCycleDay: data?.room?.billingCycleDay ?? 1,
    },
  });
  const toast = useToast();
  const { mutate, isPending } = useMutation(updateRoomFeeSetting, {
    onSuccess: (resp) => {
      toast({
        title: "Fees settings updated",
        description: "",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      refetch();
    },
    onError: (err) => {
      toast({
        title: "Failed to update fees settings",
        description: err.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    },
  });

  return (
    <chakra.form
      onSubmit={handleSubmit((req) => {
        mutate({
          roomId: data?.room?.id,
          billingCycleCreationDays: -7,
          billingCycleDay: Number(req.billingCycleDay),
          defaultFees: BigInt(Number(req.defaultFees)),
        });
      })}
    >
      <Table w="100%" size={"sm"} variant={"unstyled"} mt={2} mb={4}>
        <Tr border={"solid"} borderWidth={"1px"}>
          <Td border="solid" borderWidth={"1px"} w="50%">
            Batch Name
          </Td>
          <Td border="solid" borderWidth={"1px"}>
            {data?.room?.name}
          </Td>
        </Tr>
        <Tr border={"solid"} borderWidth={"1px"}>
          <Td border="solid" borderWidth={"1px"} w="50%">
            Default Fees
          </Td>
          <Td border="solid" borderWidth={"1px"}>
            {formatFees(data?.room?.defaultFees ?? BigInt(0)) + " / month"}
          </Td>
        </Tr>
        <Tr border={"solid"} borderWidth={"1px"}>
          <Td border="solid" borderWidth={"1px"} w="50%">
            Billing Cycle Date
          </Td>
          <Td border="solid" borderWidth={"1px"}>
            {(data?.room?.billingCycleDay ?? 1) % 10 === 1
              ? (data?.room?.billingCycleDay ?? 1) + "st"
              : (data?.room?.billingCycleDay ?? 1) % 10 === 2
              ? (data?.room?.billingCycleDay ?? 1) + "nd"
              : (data?.room?.billingCycleDay ?? 1) % 10 === 3
              ? (data?.room?.billingCycleDay ?? 1) + "rd"
              : (data?.room?.billingCycleDay ?? 1) + "th"}
          </Td>
        </Tr>
      </Table>

      <FormControl isInvalid={!!errors?.billingCycleDay} pt={2}>
        <FormLabel>Billing Cycle Day</FormLabel>
        <Select {...register("billingCycleDay")} placeholder="Select">
          {Array.from({ length: 31 }, (_, i) => i + 1).map((key) => (
            <option value={key}>{format(new Date(2025, 0, key), "do")}</option>
          ))}
        </Select>
        <FormErrorMessage>
          {errors?.billingCycleDay && errors?.billingCycleDay?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors?.defaultFees} pt={2}>
        <FormLabel>Default Fees</FormLabel>
        <Controller
          name={"defaultFees"}
          control={control}
          render={({ field: { ...restField } }) => (
            <NumberInput {...restField} min={0}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          )}
        />

        <FormErrorMessage>
          {errors?.defaultFees && errors?.defaultFees?.message}
        </FormErrorMessage>
      </FormControl>

      <Button w="full" type="submit" isLoading={isPending} my={4}>
        Set
      </Button>
    </chakra.form>
  );
};

interface UserFeeSettingForm {
  hasCustomFees: boolean;
  customFees: number;
}

const UserFeeSetting = ({
  data,
  selectedUser,
  refetch,
}: {
  data?: GetRoomFeesAggregateResponse;
  selectedUser: RoomUser;
  refetch: () => void;
}) => {
  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm<UserFeeSettingForm>({
    defaultValues: {
      customFees: selectedUser.customFees
        ? Number(selectedUser.customFees)
        : data?.room?.defaultFees
        ? Number(data?.room?.defaultFees)
        : 0,
      hasCustomFees: selectedUser.customFees !== undefined,
    },
  });
  const hasCustomFees = watch("hasCustomFees");
  const toast = useToast();
  const { mutate, isPending } = useMutation(updateRoomEntityCustomFee, {
    onSuccess: (resp) => {
      toast({
        title: "Student fees updated",
        description: "",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      refetch();
    },
    onError: (err) => {
      toast({
        title: "Failed to update fees settings",
        description: err.message,
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    },
  });

  return (
    <chakra.form
      pb={4}
      onSubmit={handleSubmit((req) => {
        mutate({
          roomId: data?.room?.id,
          customFees: BigInt(Number(req.customFees)),
          userId: selectedUser.user?.id,
        });
      })}
    >
      <Table w="100%" size={"sm"} variant={"unstyled"} mt={2} mb={4}>
        <Tr border={"solid"} borderWidth={"1px"}>
          <Td border="solid" borderWidth={"1px"} w="50%">
            Batch Name
          </Td>
          <Td border="solid" borderWidth={"1px"}>
            {data?.room?.name}
          </Td>
        </Tr>
        <Tr border={"solid"} borderWidth={"1px"}>
          <Td border="solid" borderWidth={"1px"} w="50%">
            Batch Fees
          </Td>
          <Td border="solid" borderWidth={"1px"}>
            {formatFees(data?.room?.defaultFees ?? BigInt(0)) + " / month"}
          </Td>
        </Tr>
        <Tr border={"solid"} borderWidth={"1px"}>
          <Td border="solid" borderWidth={"1px"} w="50%">
            Custom Fees
          </Td>
          <Td border="solid" borderWidth={"1px"}>
            {selectedUser.customFees === undefined ? "No" : "Yes"}
          </Td>
        </Tr>
      </Table>

      <Text>
        Set Custom Fees for{" "}
        {selectedUser.user?.firstName + " " + selectedUser.user?.lastName} in{" "}
        {data?.room?.name}
      </Text>
      <FormControl pt={2}>
        <Controller
          control={control}
          name="hasCustomFees"
          render={({ field }) => (
            <Checkbox
              isChecked={field.value}
              onChange={() => field.onChange(!field.value)}
            >
              Enable Custom Fees
            </Checkbox>
          )}
        />
      </FormControl>

      <FormControl
        pt={2}
        isInvalid={!!errors?.customFees}
        hidden={!hasCustomFees}
      >
        <FormLabel>Custom Fees</FormLabel>
        <Controller
          name={"customFees"}
          control={control}
          render={({ field: { ...restField } }) => (
            <NumberInput {...restField} min={0}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          )}
        />

        <FormErrorMessage>
          {errors?.customFees && errors?.customFees?.message}
        </FormErrorMessage>
      </FormControl>

      <Button mt={4} w={"full"} type="submit" isLoading={isPending}>
        Set
      </Button>
    </chakra.form>
  );
};

function groupBy<T>(arr: T[], fn: (item: T) => any) {
  return arr.reduce<Record<string, T[]>>((prev, curr) => {
    const groupKey = fn(curr);
    const group = prev[groupKey] || [];
    group.push(curr);
    return { ...prev, [groupKey]: group };
  }, {});
}

const DefaulterList = ({
  data,
  defaulterListOnClose,
  markFeesOnOpen,
}: {
  data?: GetRoomFeesAggregateResponse;
  defaulterListOnClose: () => void;
  markFeesOnOpen: () => void;
}) => {
  const unpaidFees = useMemo(() => {
    return data?.fees.filter((fee) => fee.feesPaid < fee.fees) ?? [];
  }, [data]);
  const userUnpaidFees = useMemo(() => {
    return groupBy(unpaidFees, (fee) => fee.user!.id);
  }, [unpaidFees]);

  return (
    <>
      <chakra.div minH="60vh">
        <SimpleGrid columns={20} py={4} overflow={"scroll"}>
          <GridItem
            rowSpan={1}
            colSpan={8}
            border={"solid"}
            borderWidth={"0.5px"}
          >
            <Flex px={1} h="100%" alignItems={"flex-end"}>
              <Text textAlign="center" w="100%">
                Name
              </Text>
            </Flex>
          </GridItem>
          <GridItem
            colSpan={4}
            textAlign={"center"}
            border={"solid"}
            borderLeft={"none"}
            borderWidth={"0.5px"}
          >
            <Text>Last Paid Date</Text>
          </GridItem>
          <GridItem
            colSpan={4}
            textAlign={"center"}
            border={"solid"}
            borderLeft={"none"}
            borderWidth={"0.5px"}
          >
            <Text>Months Unpaid</Text>
          </GridItem>
          <GridItem
            colSpan={4}
            textAlign={"center"}
            border={"solid"}
            borderLeft={"none"}
            borderWidth={"0.5px"}
          >
            <Text>Amount Due</Text>
          </GridItem>
          {Object.entries(userUnpaidFees).map(([userId, unpaidFeesForUser]) => {
            const userPaidFees =
              data?.fees
                .filter(
                  (fee) =>
                    fee.user?.id === userId &&
                    fee.feesPaid === fee.fees &&
                    fee.paidOn !== undefined
                )
                .sort(
                  (a, b) => a.billYear - b.billYear || a.billMonth - b.billMonth
                ) ?? [];
            const defaulter = data?.room?.users.find(
              (user) => user.user?.id === userId
            );
            if (!defaulter) return null;
            return (
              <>
                <GridItem
                  fontSize={"sm"}
                  colSpan={8}
                  textAlign={"center"}
                  border={"solid"}
                  borderTop={"none"}
                  borderWidth={"0.5px"}
                >
                  {protoUserName(defaulter.user)}
                </GridItem>
                <GridItem
                  fontSize={"sm"}
                  colSpan={4}
                  textAlign={"center"}
                  border={"solid"}
                  borderLeft={"none"}
                  borderTop={"none"}
                  borderWidth={"0.5px"}
                >
                  {userPaidFees.length > 0
                    ? format(
                        userPaidFees[0].paidOn?.toDate() ?? new Date(),
                        "dd MMM yyyy"
                      )
                    : "-"}
                </GridItem>
                <GridItem
                  fontSize={"sm"}
                  colSpan={4}
                  textAlign={"center"}
                  border={"solid"}
                  borderLeft={"none"}
                  borderTop={"none"}
                  borderWidth={"0.5px"}
                >
                  {unpaidFeesForUser.length}
                </GridItem>
                <GridItem
                  colSpan={4}
                  fontSize={"sm"}
                  textAlign={"center"}
                  border={"solid"}
                  borderLeft={"none"}
                  borderTop={"none"}
                  borderWidth={"0.5px"}
                >
                  {formatFees(
                    unpaidFeesForUser.reduce(
                      (acc, fee) => acc + fee.fees,
                      BigInt(0)
                    )
                  )}
                </GridItem>
              </>
            );
          })}
        </SimpleGrid>
      </chakra.div>
      <SimpleGrid columns={20} py={4} maxH="60vh" overflow={"scroll"}>
        <GridItem
          rowSpan={1}
          colSpan={8}
          border={"solid"}
          borderWidth={"2px"}
          h="100%"
          pl={1}
        >
          <Text fontSize={"lg"} fontWeight={"bold"}>
            Total
          </Text>
        </GridItem>
        <GridItem
          rowSpan={1}
          colSpan={4}
          border={"solid"}
          borderWidth={"2px"}
          borderLeft={"none"}
          h="100%"
          pl={1}
        >
          <Text fontSize={"lg"} fontWeight={"bold"}>
            -
          </Text>
        </GridItem>
        <GridItem
          rowSpan={1}
          colSpan={4}
          border={"solid"}
          borderWidth={"2px"}
          borderLeft={"none"}
          h="100%"
          pl={1}
        >
          <Text fontSize={"lg"} fontWeight={"bold"} textAlign={"center"}>
            {Object.values(userUnpaidFees).reduce(
              (acc, fees) => acc + fees.length,
              0
            )}
          </Text>
        </GridItem>
        <GridItem
          rowSpan={1}
          colSpan={4}
          border={"solid"}
          borderWidth={"2px"}
          borderLeft={"none"}
          h="100%"
          pl={1}
        >
          <Text fontSize={"lg"} fontWeight={"bold"} textAlign={"center"}>
            {formatFees(
              Object.values(userUnpaidFees).reduce(
                (acc, fees) =>
                  acc + fees.reduce((acc, fee) => acc + fee.fees, BigInt(0)),
                BigInt(0)
              )
            )}
          </Text>
        </GridItem>
      </SimpleGrid>
      <Button
        // zIndex={1000}
        position="fixed"
        bottom={5}
        right={5}
        colorScheme="green"
        onClick={() => {
          defaulterListOnClose();
          markFeesOnOpen();
        }}
      >
        <HStack>
          <BsSave2 />
          <Text ml={1}>Mark Paid Fees</Text>
        </HStack>
      </Button>
    </>
  );
};

export default function ManageFees() {
  const { id } = useParams<{
    id: string;
  }>();
  const { isLoading, isError, error, data, refetch } = useGetRoom(id ?? "");

  const roomUser = new RoomUser();
  const [selectedUser, setSelectedUser] = useState(roomUser);
  const {
    isOpen: playerEditIsOpen,
    onOpen: playerEditOnOpen,
    onClose: playerEditOnClose,
  } = useDisclosure();
  const {
    isOpen: manageFeesIsOpen,
    onOpen: manageFeesOnOpen,
    onClose: manageFeesOnClose,
  } = useDisclosure();
  const {
    isOpen: markFeesIsOpen,
    onOpen: markFeesOnOpen,
    onClose: markFeesOnClose,
  } = useDisclosure();
  const {
    isOpen: defaulterListIsOpen,
    onOpen: defaulterListOnOpen,
    onClose: defaulterListOnClose,
  } = useDisclosure();

  if (isLoading) {
    return <Loading />;
  }
  if (isError) {
    return <DisplayError error={error} />;
  }

  return (
    <chakra.div>
      <VStack
        zIndex={1000}
        position="fixed"
        bottom={5}
        right={5}
        // px={"5%"}
      >
        <Menu autoSelect={false}>
          <MenuButton backdropFilter={"auto"} textAlign={"end"} w="100%">
            <Button
              // w="100%"
              p={2}
              size={"sm"}
              colorScheme="blue"
              whiteSpace="normal"
              borderRadius={"md"}
              zIndex={1000}
              aria-label={""}
            >
              <HStack justifyItems={"right"}>
                <Text textAlign={"left"} fontSize={"lg"} p={0}>
                  {<IoMdMore />}
                </Text>
                <Text textAlign={"left"} fontSize={"sm"}>
                  More
                </Text>
              </HStack>
            </Button>
          </MenuButton>
          <MenuList
            // backdropFilter={"auto"}
            // backdropBlur={"1px"}
            // w={"min-content"}
            // borderX={"solid"}
            justifyItems={"flex-end"}
            // border="solid"
            maxW="min-content"
            py={2}
            px={4}
            bg={"transparent"}
            shadow={"none"}
            border={"none"}
          >
            <MenuItem
              my={1}
              fontSize={"sm"}
              w="100%"
              bg={"red.500"}
              color={"white"}
              fontWeight={"bold"}
              borderRadius={"sm"}
              onClick={() => defaulterListOnOpen()}
            >
              {<MdOutlineDisabledByDefault />}
              <Text pl={1}>Defaulter List</Text>
            </MenuItem>
            <MenuItem
              my={1}
              fontSize={"sm"}
              w="100%"
              bg={"blue.500"}
              color={"white"}
              fontWeight={"bold"}
              borderRadius={"sm"}
              justifyItems={"right"}
              onClick={() => {
                manageFeesOnOpen();
              }}
            >
              {<EditIcon />}
              <Text pl={1}>Manage Fees</Text>
            </MenuItem>
            <MenuItem
              as={Link}
              my={1}
              fontSize={"sm"}
              w="100%"
              bg={"orange.500"}
              color={"white"}
              fontWeight={"bold"}
              borderRadius={"sm"}
              to={`/fees-analysis/${data?.room?.id}`}
            >
              {<FaMagnifyingGlass />}
              <Text pl={1}>Fees Analysis</Text>
            </MenuItem>
          </MenuList>
        </Menu>
        <Button
          p={2}
          size={"sm"}
          colorScheme="green"
          whiteSpace="normal"
          borderRadius={"md"}
          zIndex={1000}
          aria-label={""}
          onClick={() => markFeesOnOpen()}
        >
          {<FaMarker />}
          <Text pl={1}>Mark Fees</Text>
        </Button>
      </VStack>
      <Center p={4}>
        {
          // Player Edit Modal
        }
        <Modal
          isOpen={playerEditIsOpen}
          onClose={playerEditOnClose}
          size={"md"}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent minW="30%" maxW="90%" minH="40%">
            <ModalHeader>
              <Heading size={"md"}>
                {selectedUser.user?.firstName +
                  " " +
                  selectedUser.user?.lastName}
              </Heading>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody h="100%" verticalAlign={"center"} w="100%">
              <UserFeeSetting
                data={data}
                refetch={refetch}
                selectedUser={selectedUser}
              />
            </ModalBody>
          </ModalContent>
        </Modal>

        {
          // Manage Fees Modal
        }
        <Modal
          isOpen={manageFeesIsOpen}
          onClose={manageFeesOnClose}
          size={"md"}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent minW="30%" maxW="90%" minH="40%" py={4}>
            <ModalHeader>
              <VStack>
                <Heading size={"lg"}>Fees Settings</Heading>
                <Heading size={"sm"}>{data?.room?.name}</Heading>
              </VStack>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody h="100%" verticalAlign={"center"} w="100%">
              <FeeSettings data={data} refetch={refetch} />
            </ModalBody>
          </ModalContent>
        </Modal>

        {
          // Mark Fees Modal
        }
        <Modal
          isOpen={markFeesIsOpen}
          onClose={markFeesOnClose}
          size={"full"}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent minW="30%" mt={"10%"}>
            <ModalHeader>
              <VStack>
                <Heading size={"xl"}>Mark Fees</Heading>
                <Heading size={"md"}>{data?.room?.name}</Heading>
              </VStack>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody h="100%" verticalAlign={"center"} w="100%">
              <MarkFees data={data} refetch={refetch} />
            </ModalBody>
          </ModalContent>
        </Modal>

        {
          // DefaulterList Modal
        }
        <Modal
          isOpen={defaulterListIsOpen}
          onClose={defaulterListOnClose}
          size={"full"}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent minW="30%" mt={"10%"}>
            <ModalHeader>
              <VStack>
                <Heading size={"xl"}>Defaulter List</Heading>
                <Heading size={"md"}>{data?.room?.name}</Heading>
              </VStack>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody h="100%" w="100%">
              <DefaulterList
                data={data}
                defaulterListOnClose={defaulterListOnClose}
                markFeesOnOpen={markFeesOnOpen}
              />
            </ModalBody>
          </ModalContent>
        </Modal>

        <VStack>
          <Heading size={"xl"}>Fees Management</Heading>
          <Heading size={"lg"}>Batch : {data?.room?.name}</Heading>
        </VStack>
      </Center>
      <Center mb={16}>
        <VStack w="full">
          {data?.room?.users.map((user, index) => (
            <UserFees
              defaultFees={data?.room?.defaultFees ?? BigInt(0)}
              user={user}
              fees={data?.fees?.filter((fee) => fee.user?.id === user.user?.id)}
              onManage={() => {
                setSelectedUser(user);
                playerEditOnOpen();
              }}
            />
          ))}
        </VStack>
      </Center>
    </chakra.div>
  );
}
