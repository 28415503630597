// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file notice/v1/notice.proto (package notice.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message notice.v1.Notice
 */
export const Notice = /*@__PURE__*/ proto3.makeMessageType(
  "notice.v1.Notice",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created_at", kind: "message", T: Timestamp },
    { no: 4, name: "updated_at", kind: "message", T: Timestamp },
  ],
);

