// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file user/v1/user_api.proto (package user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { User } from "../../base/v1/user_pb.js";
import { UploadMedia } from "../../base/v1/media_pb.js";
import { RoleType } from "../../base/v1/role_pb.js";
import { Sport } from "../../base/v1/whiteboard_pb.js";

/**
 * @generated from enum user.v1.LevelOfTournamentParticipatedType
 */
export const LevelOfTournamentParticipatedType = /*@__PURE__*/ proto3.makeEnum(
  "user.v1.LevelOfTournamentParticipatedType",
  [
    {no: 0, name: "LEVEL_OF_TOURNAMENT_PARTICIPATED_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "LEVEL_OF_TOURNAMENT_PARTICIPATED_TYPE_AMATEUR", localName: "AMATEUR"},
    {no: 2, name: "LEVEL_OF_TOURNAMENT_PARTICIPATED_TYPE_DISTRICT", localName: "DISTRICT"},
    {no: 3, name: "LEVEL_OF_TOURNAMENT_PARTICIPATED_TYPE_STATE_ZONAL", localName: "STATE_ZONAL"},
    {no: 4, name: "LEVEL_OF_TOURNAMENT_PARTICIPATED_TYPE_STATE", localName: "STATE"},
    {no: 5, name: "LEVEL_OF_TOURNAMENT_PARTICIPATED_TYPE_NATIONAL_ZONAL", localName: "NATIONAL_ZONAL"},
    {no: 6, name: "LEVEL_OF_TOURNAMENT_PARTICIPATED_TYPE_NATIONAL", localName: "NATIONAL"},
    {no: 7, name: "LEVEL_OF_TOURNAMENT_PARTICIPATED_TYPE_CONTINENTAL", localName: "CONTINENTAL"},
    {no: 8, name: "LEVEL_OF_TOURNAMENT_PARTICIPATED_TYPE_WORLD", localName: "WORLD"},
  ],
);

/**
 * @generated from enum user.v1.HandednessType
 */
export const HandednessType = /*@__PURE__*/ proto3.makeEnum(
  "user.v1.HandednessType",
  [
    {no: 0, name: "HANDEDNESS_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "HANDEDNESS_TYPE_LEFT", localName: "LEFT"},
    {no: 2, name: "HANDEDNESS_TYPE_RIGHT", localName: "RIGHT"},
    {no: 3, name: "HANDEDNESS_TYPE_BOTH", localName: "BOTH"},
  ],
);

/**
 * @generated from enum user.v1.GenderType
 */
export const GenderType = /*@__PURE__*/ proto3.makeEnum(
  "user.v1.GenderType",
  [
    {no: 0, name: "GENDER_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "GENDER_TYPE_MALE", localName: "MALE"},
    {no: 2, name: "GENDER_TYPE_FEMALE", localName: "FEMALE"},
  ],
);

/**
 * @generated from enum user.v1.TournamentEventsType
 */
export const TournamentEventsType = /*@__PURE__*/ proto3.makeEnum(
  "user.v1.TournamentEventsType",
  [
    {no: 0, name: "TOURNAMENT_EVENTS_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "TOURNAMENT_EVENTS_TYPE_INDIVIDUAL", localName: "INDIVIDUAL"},
    {no: 2, name: "TOURNAMENT_EVENTS_TYPE_TEAM", localName: "TEAM"},
    {no: 3, name: "TOURNAMENT_EVENTS_TYPE_ALL", localName: "ALL"},
  ],
);

/**
 * @generated from message user.v1.GetProfileRequest
 */
export const GetProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.GetProfileRequest",
  [],
);

/**
 * @generated from message user.v1.GetProfileResponse
 */
export const GetProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.GetProfileResponse",
  () => [
    { no: 1, name: "user", kind: "message", T: User },
    { no: 4, name: "player_profile", kind: "message", T: PlayerProfile },
  ],
);

/**
 * @generated from message user.v1.UpdateProfileRequest
 */
export const UpdateProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.UpdateProfileRequest",
  () => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "avatar", kind: "message", T: UploadMedia },
  ],
);

/**
 * @generated from message user.v1.UpdateProfileResponse
 */
export const UpdateProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.UpdateProfileResponse",
  [],
);

/**
 * @generated from message user.v1.ListAllUsersRequest
 */
export const ListAllUsersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.ListAllUsersRequest",
  () => [
    { no: 1, name: "roles", kind: "enum", T: proto3.getEnumType(RoleType), repeated: true },
  ],
);

/**
 * @generated from message user.v1.ListAllUsersResponse
 */
export const ListAllUsersResponse = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.ListAllUsersResponse",
  () => [
    { no: 1, name: "users", kind: "message", T: User, repeated: true },
  ],
);

/**
 * @generated from message user.v1.RequestAccountDeleteRequest
 */
export const RequestAccountDeleteRequest = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.RequestAccountDeleteRequest",
  [],
);

/**
 * @generated from message user.v1.RequestAccountDeleteResponse
 */
export const RequestAccountDeleteResponse = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.RequestAccountDeleteResponse",
  [],
);

/**
 * @generated from message user.v1.PlayerProfile
 */
export const PlayerProfile = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.PlayerProfile",
  () => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "local_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "national_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "international_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "tournaments_participated", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "podium_finishes", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "no_of_days_participated_curr_year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "level_of_tournament_participated", kind: "enum", T: proto3.getEnumType(LevelOfTournamentParticipatedType) },
    { no: 10, name: "handedness", kind: "enum", T: proto3.getEnumType(HandednessType) },
    { no: 11, name: "no_of_days_injured", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "no_of_days_sick", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "height", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 14, name: "weight", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 15, name: "dob", kind: "message", T: Timestamp },
    { no: 16, name: "no_of_years_playing", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 17, name: "tournament_events", kind: "enum", T: proto3.getEnumType(TournamentEventsType) },
    { no: 18, name: "gender", kind: "enum", T: proto3.getEnumType(GenderType) },
    { no: 19, name: "avatar_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "sport", kind: "enum", T: proto3.getEnumType(Sport) },
    { no: 21, name: "attendace_summary", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * @generated from message user.v1.UpdatePlayerProfileRequest
 */
export const UpdatePlayerProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.UpdatePlayerProfileRequest",
  () => [
    { no: 1, name: "player_profile", kind: "message", T: PlayerProfile },
  ],
);

/**
 * @generated from message user.v1.UpdatePlayerProfileResponse
 */
export const UpdatePlayerProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.UpdatePlayerProfileResponse",
  [],
);

/**
 * @generated from message user.v1.GetPlayerProfileRequest
 */
export const GetPlayerProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.GetPlayerProfileRequest",
  () => [
    { no: 1, name: "player_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message user.v1.GetPlayerProfileResponse
 */
export const GetPlayerProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.GetPlayerProfileResponse",
  () => [
    { no: 1, name: "player_profile", kind: "message", T: PlayerProfile },
  ],
);

/**
 * @generated from message user.v1.UploadAvatarRequest
 */
export const UploadAvatarRequest = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.UploadAvatarRequest",
  () => [
    { no: 1, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message user.v1.UploadAvatarResponse
 */
export const UploadAvatarResponse = /*@__PURE__*/ proto3.makeMessageType(
  "user.v1.UploadAvatarResponse",
  [],
);

