// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file attendance/v1/attendance.proto (package attendance.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Room } from "../../room/v1/room_pb.js";
import { User } from "../../base/v1/user_pb.js";

/**
 * @generated from message attendance.v1.Session
 */
export const Session = /*@__PURE__*/ proto3.makeMessageType(
  "attendance.v1.Session",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "room", kind: "message", T: Room },
    { no: 4, name: "date", kind: "message", T: Timestamp },
    { no: 5, name: "attendances", kind: "message", T: Attendance, repeated: true },
    { no: 6, name: "is_done", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message attendance.v1.Attendance
 */
export const Attendance = /*@__PURE__*/ proto3.makeMessageType(
  "attendance.v1.Attendance",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user", kind: "message", T: User },
    { no: 3, name: "is_present", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "created_at", kind: "message", T: Timestamp },
    { no: 5, name: "updated_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message attendance.v1.Holiday
 */
export const Holiday = /*@__PURE__*/ proto3.makeMessageType(
  "attendance.v1.Holiday",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "date", kind: "message", T: Timestamp },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

