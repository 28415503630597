// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file register/v1/register.proto (package register.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { RoleType } from "../../base/v1/role_pb.js";

/**
 * @generated from message register.v1.StartRegistrationRequest
 */
export const StartRegistrationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "register.v1.StartRegistrationRequest",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message register.v1.StartRegistrationResponse
 */
export const StartRegistrationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "register.v1.StartRegistrationResponse",
  () => [
    { no: 1, name: "registration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message register.v1.VerifyRegistrationOTPRequest
 */
export const VerifyRegistrationOTPRequest = /*@__PURE__*/ proto3.makeMessageType(
  "register.v1.VerifyRegistrationOTPRequest",
  () => [
    { no: 1, name: "registration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "otp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message register.v1.VerifyRegistrationOTPResponse
 */
export const VerifyRegistrationOTPResponse = /*@__PURE__*/ proto3.makeMessageType(
  "register.v1.VerifyRegistrationOTPResponse",
  [],
);

/**
 * @generated from message register.v1.ResendRegistrationOTPRequest
 */
export const ResendRegistrationOTPRequest = /*@__PURE__*/ proto3.makeMessageType(
  "register.v1.ResendRegistrationOTPRequest",
  () => [
    { no: 1, name: "registration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message register.v1.ResendRegistrationOTPResponse
 */
export const ResendRegistrationOTPResponse = /*@__PURE__*/ proto3.makeMessageType(
  "register.v1.ResendRegistrationOTPResponse",
  [],
);

/**
 * @generated from message register.v1.CompleteRegistrationRequest
 */
export const CompleteRegistrationRequest = /*@__PURE__*/ proto3.makeMessageType(
  "register.v1.CompleteRegistrationRequest",
  () => [
    { no: 1, name: "registration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "otp", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "mobile", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "role", kind: "enum", T: proto3.getEnumType(RoleType) },
  ],
);

/**
 * @generated from message register.v1.CompleteRegistrationResponse
 */
export const CompleteRegistrationResponse = /*@__PURE__*/ proto3.makeMessageType(
  "register.v1.CompleteRegistrationResponse",
  [],
);

