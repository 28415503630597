// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file notification/v1/notification_api.proto (package notification.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { SortDirection } from "../../base/v1/sort_pb.js";
import { Notification } from "./notification_pb.js";

/**
 * @generated from message notification.v1.ListNotificationsRequest
 */
export const ListNotificationsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "notification.v1.ListNotificationsRequest",
  () => [
    { no: 1, name: "page", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
  ],
);

/**
 * @generated from message notification.v1.ListNotificationsResponse
 */
export const ListNotificationsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "notification.v1.ListNotificationsResponse",
  () => [
    { no: 1, name: "notifications", kind: "message", T: Notification, repeated: true },
  ],
);

/**
 * @generated from message notification.v1.GetNotificationsCountRequest
 */
export const GetNotificationsCountRequest = /*@__PURE__*/ proto3.makeMessageType(
  "notification.v1.GetNotificationsCountRequest",
  [],
);

/**
 * @generated from message notification.v1.GetNotificationsCountResponse
 */
export const GetNotificationsCountResponse = /*@__PURE__*/ proto3.makeMessageType(
  "notification.v1.GetNotificationsCountResponse",
  () => [
    { no: 1, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

