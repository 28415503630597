// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file auth/v1/auth.proto (package auth.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message auth.v1.LoginRequest
 */
export const LoginRequest = /*@__PURE__*/ proto3.makeMessageType(
  "auth.v1.LoginRequest",
  () => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message auth.v1.LoginResponse
 */
export const LoginResponse = /*@__PURE__*/ proto3.makeMessageType(
  "auth.v1.LoginResponse",
  () => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message auth.v1.RegisterRequest
 */
export const RegisterRequest = /*@__PURE__*/ proto3.makeMessageType(
  "auth.v1.RegisterRequest",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message auth.v1.RegisterResponse
 */
export const RegisterResponse = /*@__PURE__*/ proto3.makeMessageType(
  "auth.v1.RegisterResponse",
  [],
);

/**
 * @generated from message auth.v1.ChangePasswordRequest
 */
export const ChangePasswordRequest = /*@__PURE__*/ proto3.makeMessageType(
  "auth.v1.ChangePasswordRequest",
  () => [
    { no: 1, name: "old_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "new_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message auth.v1.ChangePasswordResponse
 */
export const ChangePasswordResponse = /*@__PURE__*/ proto3.makeMessageType(
  "auth.v1.ChangePasswordResponse",
  [],
);

/**
 * @generated from message auth.v1.VerifySessionRequest
 */
export const VerifySessionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "auth.v1.VerifySessionRequest",
  [],
);

/**
 * @generated from message auth.v1.VerifySessionResponse
 */
export const VerifySessionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "auth.v1.VerifySessionResponse",
  [],
);

/**
 * @generated from message auth.v1.LogoutRequest
 */
export const LogoutRequest = /*@__PURE__*/ proto3.makeMessageType(
  "auth.v1.LogoutRequest",
  [],
);

/**
 * @generated from message auth.v1.LogoutResponse
 */
export const LogoutResponse = /*@__PURE__*/ proto3.makeMessageType(
  "auth.v1.LogoutResponse",
  [],
);

/**
 * @generated from message auth.v1.SetFCMTokenRequest
 */
export const SetFCMTokenRequest = /*@__PURE__*/ proto3.makeMessageType(
  "auth.v1.SetFCMTokenRequest",
  () => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message auth.v1.SetFCMTokenResponse
 */
export const SetFCMTokenResponse = /*@__PURE__*/ proto3.makeMessageType(
  "auth.v1.SetFCMTokenResponse",
  [],
);

