import { useEffect, useState } from "react";
import constate from "constate";
import { getUserAggregateProfile } from "proto/aggregate/v1/aggregate_api-AggregateAPI_connectquery";
import { useQuery } from "@connectrpc/connect-query";
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
import { authAPIWithSessionClient } from "clients";
import { App as CapacitorApp, URLOpenListenerEvent } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { useNavigate } from "react-router-dom";
import { getNotificationsCount } from "proto/notification/v1/notification_api-NotificationAPI_connectquery";

const useAggProfile = () => {
  const { isLoading, isFetching, isError, error, data, refetch } = useQuery(
    getUserAggregateProfile
  );

  return {
    isLoading: isLoading || isFetching,
    isError,
    error,
    data,
    refetch,
  };
};

const useGetNotificationCount = () => {
  const { isLoading, isFetching, isError, error, data, refetch } = useQuery(
    getNotificationsCount
  );

  return {
    isLoading: isLoading || isFetching,
    isError,
    error,
    data,
    refetch,
  };
};

const useGlobalState = () => {
  const [loaded, setLoaded] = useState(false);
  const { data, error, isError, isLoading, refetch } = useAggProfile();
  const {
    data: notificationCountData,
    error: notificationError,
    isError: isNotificationError,
    isLoading: isNotificationLoading,
    refetch: refetchNotification,
  } = useGetNotificationCount();

  const navigate = useNavigate();

  // we will only show loading for the time we fetch data
  useEffect(() => {
    if (loaded || isLoading) return;
    setLoaded(true);
  }, [loaded, isLoading]);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === "granted") {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener("registration", async (token: Token) => {
      try {
        await authAPIWithSessionClient.setFCMToken({ token: token.value });
      } catch (error) {
        console.log(error);
      }
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener("registrationError", (error: any) => {
      console.log("Error on registration: " + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        refetchNotification();
        console.log("Push received: " + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        refetchNotification();
        console.log("Push action performed: " + JSON.stringify(notification));
      }
    );

    return () => {
      PushNotifications.removeAllListeners();
    };
  }, [refetchNotification]);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;

    CapacitorApp.addListener("backButton", ({ canGoBack }) => {
      if (!canGoBack) {
        CapacitorApp.exitApp();
      } else {
        window.history.back();
      }
    });

    CapacitorApp.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const slug = event.url.split(".in").pop();
      if (slug) {
        navigate(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });

    return () => {
      CapacitorApp.removeAllListeners();
    };
  }, [navigate]);

  return {
    data,
    error,
    isError,
    loaded,
    isFetching: isLoading,
    refetch,
    notificationCountData,
    notificationError,
    isNotificationError,
    isNotificationLoading,
    refetchNotification,
  };
};

const [GlobalStateProvider, useGlobalStateContext] = constate(useGlobalState);

export { GlobalStateProvider, useGlobalStateContext };
