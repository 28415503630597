// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file room/v1/room.proto (package room.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { User } from "../../base/v1/user_pb.js";
import { AccessType } from "../../base/v1/access_pb.js";

/**
 * @generated from message room.v1.SessionRule
 */
export const SessionRule = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.SessionRule",
  () => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "rrule", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "start_minute", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "end_minute", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message room.v1.Room
 */
export const Room = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.Room",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "color", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "users", kind: "message", T: RoomUser, repeated: true },
    { no: 5, name: "is_shared_to_me", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "owner", kind: "message", T: User },
    { no: 7, name: "session_rules", kind: "message", T: SessionRule, repeated: true },
    { no: 8, name: "created_at", kind: "message", T: Timestamp },
    { no: 9, name: "batch_strength", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "default_fees", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "billing_cycle_day", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "billing_cycle_creation_days", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message room.v1.RoomUser
 */
export const RoomUser = /*@__PURE__*/ proto3.makeMessageType(
  "room.v1.RoomUser",
  () => [
    { no: 1, name: "user", kind: "message", T: User },
    { no: 2, name: "access_type", kind: "enum", T: proto3.getEnumType(AccessType) },
    { no: 3, name: "custom_fees", kind: "scalar", T: 3 /* ScalarType.INT64 */, opt: true },
  ],
);

