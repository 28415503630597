// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file diary/v1/diary_api.proto (package diary.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { DiaryPage } from "./diary_pb.js";
import { User } from "../../base/v1/user_pb.js";

/**
 * @generated from message diary.v1.CreateDiaryPageRequest
 */
export const CreateDiaryPageRequest = /*@__PURE__*/ proto3.makeMessageType(
  "diary.v1.CreateDiaryPageRequest",
  () => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "date", kind: "message", T: Timestamp, opt: true },
    { no: 4, name: "tag", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message diary.v1.CreateDiaryPageResponse
 */
export const CreateDiaryPageResponse = /*@__PURE__*/ proto3.makeMessageType(
  "diary.v1.CreateDiaryPageResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message diary.v1.UpdateDiaryPageRequest
 */
export const UpdateDiaryPageRequest = /*@__PURE__*/ proto3.makeMessageType(
  "diary.v1.UpdateDiaryPageRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message diary.v1.UpdateDiaryPageResponse
 */
export const UpdateDiaryPageResponse = /*@__PURE__*/ proto3.makeMessageType(
  "diary.v1.UpdateDiaryPageResponse",
  [],
);

/**
 * @generated from message diary.v1.ListDiaryPagesRequest
 */
export const ListDiaryPagesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "diary.v1.ListDiaryPagesRequest",
  [],
);

/**
 * @generated from message diary.v1.ListDiaryPagesResponse
 */
export const ListDiaryPagesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "diary.v1.ListDiaryPagesResponse",
  () => [
    { no: 1, name: "diary_pages", kind: "message", T: DiaryPage, repeated: true },
  ],
);

/**
 * @generated from message diary.v1.ListStudentsRequest
 */
export const ListStudentsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "diary.v1.ListStudentsRequest",
  () => [
    { no: 1, name: "room_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message diary.v1.ListStudentsResponse
 */
export const ListStudentsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "diary.v1.ListStudentsResponse",
  () => [
    { no: 1, name: "students", kind: "message", T: User, repeated: true },
  ],
);

/**
 * @generated from message diary.v1.ListStudentDiaryPagesRequest
 */
export const ListStudentDiaryPagesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "diary.v1.ListStudentDiaryPagesRequest",
  () => [
    { no: 1, name: "student_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message diary.v1.ListStudentDiaryPagesResponse
 */
export const ListStudentDiaryPagesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "diary.v1.ListStudentDiaryPagesResponse",
  () => [
    { no: 1, name: "diary_pages", kind: "message", T: DiaryPage, repeated: true },
  ],
);

/**
 * @generated from message diary.v1.GetDiaryPageRequest
 */
export const GetDiaryPageRequest = /*@__PURE__*/ proto3.makeMessageType(
  "diary.v1.GetDiaryPageRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message diary.v1.GetDiaryPageResponse
 */
export const GetDiaryPageResponse = /*@__PURE__*/ proto3.makeMessageType(
  "diary.v1.GetDiaryPageResponse",
  () => [
    { no: 1, name: "diary_page", kind: "message", T: DiaryPage },
  ],
);

/**
 * @generated from message diary.v1.GetDiarySettingRequest
 */
export const GetDiarySettingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "diary.v1.GetDiarySettingRequest",
  [],
);

/**
 * @generated from message diary.v1.GetDiarySettingResponse
 */
export const GetDiarySettingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "diary.v1.GetDiarySettingResponse",
  () => [
    { no: 1, name: "shared_with_users", kind: "message", T: User, repeated: true },
  ],
);

/**
 * @generated from message diary.v1.ShareDiaryRequest
 */
export const ShareDiaryRequest = /*@__PURE__*/ proto3.makeMessageType(
  "diary.v1.ShareDiaryRequest",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message diary.v1.ShareDiaryResponse
 */
export const ShareDiaryResponse = /*@__PURE__*/ proto3.makeMessageType(
  "diary.v1.ShareDiaryResponse",
  [],
);

/**
 * @generated from message diary.v1.UnShareDiaryRequest
 */
export const UnShareDiaryRequest = /*@__PURE__*/ proto3.makeMessageType(
  "diary.v1.UnShareDiaryRequest",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message diary.v1.UnShareDiaryResponse
 */
export const UnShareDiaryResponse = /*@__PURE__*/ proto3.makeMessageType(
  "diary.v1.UnShareDiaryResponse",
  [],
);

