// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file chat/v1/chat_api.proto (package chat.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateChatRequest, CreateChatResponse, ListChatsByFromUserRequest, ListChatsByFromUserResponse, ListChatsFromUsersRequest, ListChatsFromUsersResponse, ListChatsRequest, ListChatsResponse, ListChatsToUserRequest, ListChatsToUserResponse } from "./chat_api_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * ChatAPI service
 *
 * @generated from service chat.v1.ChatAPI
 */
export const ChatAPI = {
  typeName: "chat.v1.ChatAPI",
  methods: {
    /**
     * @generated from rpc chat.v1.ChatAPI.CreateChat
     */
    createChat: {
      name: "CreateChat",
      I: CreateChatRequest,
      O: CreateChatResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chat.v1.ChatAPI.ListChatsToUser
     */
    listChatsToUser: {
      name: "ListChatsToUser",
      I: ListChatsToUserRequest,
      O: ListChatsToUserResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chat.v1.ChatAPI.ListChats
     */
    listChats: {
      name: "ListChats",
      I: ListChatsRequest,
      O: ListChatsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chat.v1.ChatAPI.ListChatsByFromUser
     */
    listChatsByFromUser: {
      name: "ListChatsByFromUser",
      I: ListChatsByFromUserRequest,
      O: ListChatsByFromUserResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc chat.v1.ChatAPI.ListChatsFromUsers
     */
    listChatsFromUsers: {
      name: "ListChatsFromUsers",
      I: ListChatsFromUsersRequest,
      O: ListChatsFromUsersResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

